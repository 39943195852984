<template>
  <v-btn
    :block="block"
    :x-large="xLarge"
    :color="color"
    :outlined="outlined"
    :disabled="loading || disabled"
    :loading="loading"
    :to="to"
    :type="type"
    class="rounded-lg capitalize"
    @click="$emit('click')"
    elevation="0"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "app-button",
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    xLarge: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "button",
    },
  },
};
</script>

<style lang="scss" scoped></style>
