<template>
  <div class="desktop-view">
    <v-app>
      <v-main>
        <router-view />
        <GlobalPopup />
        <BottomSheet />
      </v-main>

      <v-dialog
        v-model="isOffline"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card class="offline-card">
          <v-card-text>
            <v-icon size="120" color="primary"
              >mdi-access-point-network-off</v-icon
            >
            <h1 style="line-height:1.2;" class="my-5">
              No internet available. Please connect to the internet.
            </h1>
            <app-button @click="reloadApp">Reload App</app-button>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-app>

    <notifications
      position="top center"
      group="push-notification"
      animation-type="velocity"
    >
      <template slot="body" slot-scope="props">
        <push-notification-bar :notification="props" />
      </template>
    </notifications>
  </div>
</template>

<script>
import GlobalPopup from "@/components/global-popup/index.vue";
import BottomSheet from "@/components/bottom-sheet/bottom-sheet";
import PushNotificationBar from "@/components/notification/push-notification-bar";
import { mapMutations, createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");

import InitializeNotifications from "@/plugins/push-notifications";
// import { fetchToken } from "@/plugins/upload-service";
import MixDevice from "@/mixin/mix-device.js";
import { uploadDeviceToken } from "@/helpers";

export default {
  name: "App",
  components: {
    GlobalPopup,
    BottomSheet,
    PushNotificationBar,
  },
  mixins: [MixDevice],
  data: () => ({
    isOffline: false,
  }),
  computed: {
    ...popupModule.mapState(["show", "showSheet"]),
  },
  methods: {
    ...mapMutations(["SET_CORDOVA"]),
    ...popupModule.mapMutations(["CLEAR_POPUP", "CLEAR_SHEET"]),
    deviceReadyListner() {
      this.SET_CORDOVA(true);
      InitializeNotifications();

      if (this.isIOS) {
        window.StatusBar.styleDefault();
      }

      // hide the splash screen
      setTimeout(() => {
        navigator.splashscreen.hide();
        this.updateBrowser();
      }, 2000);
    },
    toggleBottomBar(value) {
      this.$store.commit("SET_BOTTOM_BAR", value);
    },
    updateBrowser() {
      if (window.cordova.InAppBrowser) {
        window.open = window.cordova.InAppBrowser.open;
      }

      if (!window.cordova.plugins) return;

      window.cordova.plugins.browsertab = {};

      window.cordova.plugins.browsertab.isAvailable = function(success) {
        window.SafariViewController.isAvailable(success);
      };

      window.cordova.plugins.browsertab.openUrl = function(url) {
        window.SafariViewController.show(
          { url: url },
          function() {},
          function() {}
        );
      };

      window.cordova.plugins.browsertab.close = function() {
        window.SafariViewController.hide();
      };

      window.handleOpenURL = async (url) => {
        if (this.isIOS) {
          const token = url.replace("com.mpawer.co://", "");
          if (token) {
            if (token.includes("error_msg")) {
              const msg = token.replace("error_msg=");
              window.showAlert(msg, "Error");
            }
            localStorage.setItem("token", "Bearer " + token);
            this.$router.replace("/");
            await uploadDeviceToken(true);
          }
        }
      };
    },
    handleBackButton() {
      const { fullPath } = this.$route;
      const { show, showSheet } = this;

      if (window.stopBack) return;

      if (
        (fullPath === "/" ||
          fullPath === "/login" ||
          fullPath === "/pre-login") &&
        !show
      ) {
        navigator.app.exitApp();
      } else if (show) {
        this.CLEAR_POPUP();
      } else if (showSheet) {
        this.CLEAR_SHEET();
      } else {
        this.$router.back();
      }
    },
    offlineHandler() {
      window.stopBack = true;
      this.isOffline = true;
    },
    onlineHandler() {
      window.stopBack = false;
      this.isOffline = false;
    },
    reloadApp() {
      window.location.reload();
    },
    addListeners() {
      document.addEventListener("deviceready", this.deviceReadyListner, false);
      document.addEventListener("backbutton", this.handleBackButton, false);
      document.addEventListener("offline", this.offlineHandler, false);
      document.addEventListener("online", this.onlineHandler, false);
    },
  },
  mounted() {
    this.addListeners();
    // upload token
    // fetchToken();
  },
};
</script>

<style lang="scss">
body {
  background-color: #ddd;
}
@media screen and (min-width: 768px) {
  $max-width: 500px;
  .desktop-view {
    max-width: $max-width;
    max-height: calc(100vh - 4rem);
    margin: 2rem auto;
    overflow: hidden;

    .page-content {
      height: calc(100vh - 4rem);
      overflow: auto;
      padding-bottom: 8rem;

      &::-webkit-scrollbar {
        width: 1px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: transparent;
        outline: 1px solid transparent;
      }

      .auth-container {
        min-height: calc(100vh - 24px - 4rem);
      }
    }
    // .v-main {
    //   padding-top: 0 !important;
    // }

    .v-dialog__content,
    .v-item-group.v-bottom-navigation--fixed,
    .v-sheet.v-app-bar.v-app-bar--fixed {
      max-width: $max-width;
      left: 50% !important;
      transform: translateX(-50%) !important;
    }

    .v-sheet.v-app-bar.v-app-bar--fixed {
      top: 2rem;
    }

    .v-item-group.v-bottom-navigation--fixed {
      bottom: 2rem;
    }

    .v-btn--fixed {
      left: 63% !important;
      bottom: 100px !important;
      transform: translateX(-50%) !important;
    }

    #tinder {
      padding-bottom: 70px;
    }

    .map-page .over-flow,
    .map-page .pre-vet {
      bottom: 5rem !important;
    }

    .map-page .toggle-vet-view {
      bottom: 200px !important;
    }
  }
}

.offline-card {
  display: grid;
  place-items: center;
  text-align: center;
}
</style>
