<template>
  <v-container class="py-5 px-8" :key="change">
    <h3 class="mb-3">{{ $vuetify.lang.t("$vuetify.add_filters") }}</h3>

    <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.species") }}</p>
    <PetCategory
      v-model="categoryId"
      :wrap="true"
      @selected="CLEAR_SELECTED_RACE"
    />

    <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.gender") }}</p>
    <ToggleButtons v-model="gender" :lists="genderList" />

    <p class="subtitle-1">{{ $vuetify.lang.t("$vuetify.race") }}</p>
    <div>
      <v-chip
        v-for="race in selectedRace"
        :key="race.name"
        class="mr-2 mb-2"
        color="primary"
      >
        {{ race.name }}
      </v-chip>
    </div>
    <app-button class="mb-3" outlined @click="openRaceModal">
      <v-icon>mdi-plus</v-icon> {{ $vuetify.lang.t("$vuetify.add_race") }}
    </app-button>

    <!-- <p class="subtitle-1">What Are You interested in?</p>
    <RadioButtons v-model="filter.interest" :options="interestList" /> -->

    <!-- <p class="subtitle-1">Region</p>
    <RadioButtons v-model="region" :options="regionList" /> -->

    <div class="d-flex align-items-center justify-content-between mt-5">
      <app-button
        class="mr-4"
        color="grey-light"
        :block="false"
        @click="closeSheet"
        >{{ $vuetify.lang.t("$vuetify.close") }}</app-button
      >
      <app-button
        style="flex:1;"
        :disabled="disableApply"
        :block="false"
        @click="applyFilter"
        >{{ $vuetify.lang.t("$vuetify.apply_filters") }}</app-button
      >
    </div>

    <div class="text-center mt-5">
      <v-btn text color="red" @click="clearFilter">
        {{ $vuetify.lang.t("$vuetify.clear_all_filters") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import PetCategory from "@/components/pet-category-list/category-list";
import ToggleButtons from "@/components/form/toggle-btn";
// import RadioButtons from "@/components/form/radio-buttons";

import { createNamespacedHelpers } from "vuex";
const petModule = createNamespacedHelpers("pet");
const popupModule = createNamespacedHelpers("popup");

// dummy data;
import { GENDER_LIST, INTEREST_LIST, REGION_LIST } from "@/constants";
export default {
  name: "pet-filter",
  components: { PetCategory, ToggleButtons },
  data() {
    return {
      categoryId: "",
      gender: "",
      change: false,
    };
  },
  computed: {
    ...petModule.mapState(["selectedRace", "filter"]),
    genderList() {
      return GENDER_LIST.map((g) => {
        return {
          ...g,
          label: this.$vuetify.lang.t(`$vuetify.${g.value}`),
        };
      });
    },
    interestList() {
      return INTEREST_LIST;
    },
    regionList() {
      return REGION_LIST;
    },
    disableApply() {
      // const race_id = this.selectedRace.map((race) => race.id);
      const { gender, categoryId } = this;
      return !gender && !categoryId;
    },
  },
  methods: {
    ...petModule.mapActions(["Fetch_PET_RACE", "FETCH_PET_LIST"]),
    ...petModule.mapMutations([
      "CLEAR_SELECTED_RACE",
      "UPDATE_PET_FILTER",
      "CLEAR_ALL_FILTERS",
      "SET_PET_PAGINATION",
      "CLEAR_PETS_LIST",
    ]),
    ...popupModule.mapMutations(["SET_POPUP", "CLEAR_SHEET"]),
    openRaceModal() {
      // && species !== "other"
      if (this.categoryId) {
        this.Fetch_PET_RACE(this.categoryId);
        this.SET_POPUP({
          show: true,
          component: "PetRace",
          modalOptions: { persistent: true },
        });
      } else {
        window.showAlert("Please Select Any Pet Category");
      }
    },
    applyFilter() {
      const race_id = this.selectedRace.map((race) => race.id);
      const { gender, categoryId } = this;
      this.UPDATE_PET_FILTER({ categoryId });
      this.UPDATE_PET_FILTER({ gender });
      this.UPDATE_PET_FILTER({ race_id });
      this.makeRequestForPets();
    },
    clearFilter() {
      this.CLEAR_ALL_FILTERS();
      this.makeRequestForPets();
    },
    makeRequestForPets() {
      this.CLEAR_SHEET();
      this.CLEAR_PETS_LIST();
      this.SET_PET_PAGINATION({ page: 1, hasNext: true });
      this.FETCH_PET_LIST();
    },
    closeSheet() {
      this.region = "";
      this.gender = "";
      // this.CLEAR_ALL_FILTERS();
      this.CLEAR_SHEET();
    },
  },
  mounted() {
    const { categoryId, gender } = this.$store.state.pet.filter;
    this.categoryId = categoryId;
    this.gender = gender;
    this.change = !this.change;
  },
};
</script>

<style lang="scss" scoped></style>
