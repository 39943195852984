import TestPopup from "@/components/global-popup/components/test";
import PetRace from "@/components/global-popup/components/pet-race";
import PetPopup from "@/components/pet/pet-popup";
import ZoomImage from "@/components/zoom-image/zoom-image";

export default {
  TestPopup,
  PetRace,
  PetPopup,
  ZoomImage
};
