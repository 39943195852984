export default {
  // Onboarding
  adoption_in_few_clicks: 'Adoptie in enkele klikken',
  get_started: 'Aan de slag',
  share_animal_care: 'Deel de zorg voor dieren',

  // Login Screen
  login: 'Aanmelden',
  enter_your_email_and_password: 'Breng je e-mailadres en wachtwoord in',
  email: 'E-mail',
  password: 'Wachtwoord',
  create_new_account: 'Nieuwe account aanmaken',
  or_signup_with: 'Of meld aan met',
  and: 'en',
  others: 'anderen',
  dogs: 'Honden',
  cats: 'Katten',
  other_animal: 'Andere dieren',

  // Create New Account
  create_account: 'Account aanmaken',
  enter_your_details_below_and_get_started:
    'Vul  je gegevens aan en ga aan de slag',
  name: 'Naam',
  phone_number: 'Telefoonnummer',
  enter_your_address: 'Adres',
  gender: 'Geslacht',
  male: 'Mannelijk',
  female: 'Vrouwelijk',
  other: 'Ander',
  next: 'Volgende',
  back: 'Terug',
  already_have_a_account: 'Hebt u reeds een account?',
  step_1_of_2: 'Stap 1 van 2',
  step_2_of_2: 'Stap 2 van 2',
  confirm_password: 'Bevestig wachtwoord',

  // Preferences
  preferences: 'Voorkeuren',
  family: 'Familie',
  kids_in_house: 'Kinderen in huis',
  skip: 'Sla over',
  do_you_have_a_garden_in_your_house: 'Heb je een tuin?',
  garden_size: 'Tuingrootte',
  no_garden: 'Geen tuin',
  less_than_200_square_meter: 'Minder dan 200 vierkante meter',
  more_than_200_square_meter: 'Meer dan 200 vierkante meter',
  how_many_animals_you_have_in_house: 'Hoeveel andere dieren heb je in huis?',

  // Fogot Password
  forgot_your_password: 'Wachtwoord vergeten?',
  lets_get_you_new_password: 'Maak een nieuw wachtwoord aan',
  submit: 'Indienen',
  verify_your_otp: 'Verifieer je OTP (eenmalig wachtwoord)',
  enter_the_6_digit_code_you_received_in_your_email:
    'Voer de 6-cijferige code in die je via e-mail hebt ontvangen',
  verify: 'Verifieer',

  //Social Wall
  enter_caption: 'Onderschrift',
  publish: 'Publiceer',
  upload_with: 'Upload met',
  add_your_comment: 'Voeg je commentaar bij',
  block_user: 'Gebruiker blokkeren',
  report_post: 'Rapporteer bericht',
  terms_check_label:
    'Door uw account op dit platform aan te maken gaat u akkoord met onze',
  terms_check_label1:
    'Door uw account op dit platform aan te maken gaat u akkoord met onze',
  enter_a_reason: 'Voer een reden in',
  report_post_detail:
    'Help ons te begrijpen wat er met deze post gebeurt. Hoe zou je het omschrijven?',
  report_user_detail:
    'Help ons te begrijpen wat er met deze gebruiker aan de hand is. Hoe zou je het omschrijven?',
  thanks_for_reporting: 'Dank u voor het indienen van een verslag.',
  review_for_reporting:
    'Wij zullen uw melding bekijken en u binnen 24 uur van onze beslissing op de hoogte brengen.',
  pawed_by: 'Pawed door',

  //Pet list screen
  add_filters: 'Filters toevoegen',
  species: 'Diersoort',
  race: 'Ras',
  add_race: 'Ras toevoegen',
  close: 'Sluit',
  apply_filters: 'Filters toepassen',
  clear_all_filters: 'Filters resetten',
  hi_human: 'Hallo!',
  are_you_ready_to_be_my_new_family: 'Wil jij mijn nieuw  baasje worden?',
  lets_explore: 'Ontdek hier',
  featured_pet: 'In de kijker!',
  all_listings: 'Alle dieren',
  no_pet_found: 'Geen dier gevonden',
  no_featured_pet: 'Geen dieren in de kijker',

  //Pet detail
  looking_for_a_new_home: 'Zoekt een nieuwe thuis',
  go_for_a_walk_with_someone: 'Gaan wandelen',
  info: 'Info',
  owner: 'Eigenaar',
  active_hour: 'Actieve uren',
  kids_firendy: 'Houdt van kinderen',
  not_kids_firendy: 'Houdt niet van kinderen',
  dogs_firendy: 'Houdt van honden',
  not_dogs_firendy: 'Houdt niet van honden',
  other_animals_friendly: 'Houdt van andere dieren',
  not_other_animals_friendly: 'Houdt niet van andere dieren',
  i_am_interested: 'Ik ben geïnteresseerd',
  what_are_you_interested_in: 'Waarin ben je geïnteresseerd?',
  disclaimer: 'Disclaimer',
  your_contact_details: 'Uw contactgegevens',
  edit: 'Aanpassen',
  submit_request: 'Aanvraag indienen',
  no_description: 'Geen beschrijving',

  //Vet screen
  find_vets_near_you: 'Dierenartsen in de buurt',
  list: 'Lijst',
  filters: 'Filters',
  use_my_current_location: 'Gebruik huidige locatie',
  vet_detail: 'Info dierenarts',
  opening_hours: 'Openingsuren',
  seller_detail: 'Verkopersgegevens',
  seller_all_posts: 'Verkoper Alle berichten',
  seller_all_pets: 'Verkoper Alle huisdieren',

  //Notification screen
  notifications: 'Meldingen',
  no_notification_for_now: 'Momenteel geen meldingen...!',
  mark_all_read: 'Markeer als gelezen',
  photo: 'Foto',

  //Profile screen
  adoption_requests: 'Adoptie aanvragen',
  no_list: 'Geen lijst',
  adoption_list: 'Adoptielijst',
  co_ownership: 'Mede-baasje',
  feed_post: 'Feedpost',
  no_post_at_the_moment: "Geen foto's",
  cute_list: 'Vind ik schattig',
  no_cute_list: 'Geen lijst',
  general_info: 'Algemene info',
  make_donation: 'Doe een donatie',
  make_donation_placeholder: 'Voer het bedrag in om te doneren',
  country_code: 'Landcode',
  edit_profile: 'Profiel aanpassen',
  create_new_password: 'Creëer nieuw wachtwoord',
  old_password: 'Oud wachtwoord',
  update_password: 'Update wachtwoord',
  privacy_policy: 'Privacybeleid',
  terms_and_conditions: 'Gebruiksvoorwaarden',
  terms_and_conditions_title: 'Gebruiksvoorwaarden mPawer',
  privacy_policy_title: 'Privacybeleid mPawer',
  logout: 'Uitloggen',
  upload_profile_image: 'Upload profielfoto',
  animal_in_house: 'Dier in huis',
  kids: 'Kinderen',
  garden: 'Tuin',
  animal: 'Dier',
  age: 'Leeftijd',
  enter_age_of_kid: 'Leeftijd kind',
  select_animal_type: 'Kies diersoort',

  phone: 'Telefoon',
  kid: 'Kind',
  change: 'Wijziging',
  change_password: 'Wachtwoord wijzigen',
  current_location: 'Huidige locatie',
  address: 'Adres',
  description: 'Beschrijving',
  closed: 'Gesloten',
  cancel: 'Annuleren',
  done: 'Gedaan',
  report_listing: 'Vermelding melden',
  adoption: 'Adoptie',
  go_for_a_walk: 'Gaan wandelen',
  in_house: 'In huis',
  travel_with_pet: 'Reizen met huisdier',
  how_would_you_like_to_own: 'Hoe zou je willen bezitten?',
  cool: 'Koel',
  is_excited_about: 'is enthousiast over nieuwe woning',
  paws_crossed: 'Pootjes gekruist! Bedankt dat je interesse hebt in',
  become_friends: 'Samen kunnen we misschien wel de beste vrienden worden. ❤️',
  please_choose_in_which: 'Kies a.u.b. op welke dagen u zult hebben',
  enter_phone: 'Voer telefoon in',
  enter_email: 'Voer email in',
  update: 'Bijwerken',
  one_tap: 'Eén tik voor de hele dag',
  two_tap: 'Dubbeltik voor Am',
  triple_tap: 'Triple Tap voor Pm',
  pet_request_submitted: 'Verzoek succesvol ingediend',
  pet_request_description:
    'Uw adoptieverzoek is ingediend bij de eigenaar, voor meer informatie kunt u de status van de aanvraag in uw profiel bekijken',
  my_profile: 'Mijn profiel',
  not: 'Niet',
  sponsored_content: 'Gesponsorde inhoud',
  search_pet: 'Zoek huisdier',
  enter_your_email: 'Voer uw e-mail in',
  save: 'Opslaan',
  setup_new_password: 'Nieuw wachtwoord instellen',
  password_updated_successfully: 'Je wachtwoord is succesvol bijgewerkt',
  delete_post: 'Verwijder gepost bericht',
  language: 'Taal',
  vets_located_near_you: 'In jouw buurt',
  get_direction: 'Route',
  search_by_zip_code: 'Zoeken op postcode',
  enter_zip_code: 'Vul de zip code in',
  search_radius: 'Zoekradius',
  no_feature_pet: 'Geen aanbevolen huisdier',
  house: 'Huis',
  search_by_category: 'Zoeken op categorie',
  promoted_content: 'Gesponsorde Inhoud',
  make_a_donation: 'Doe een donatie',
  with_this_donation: 'Met deze donatie krijgt',
  recieve_the_care: 'de zorg die nodig is!',
  amount: 'Voer het bedrag in',
  your_donation: 'Maak Uw Donatie',
  support_mpawer: 'Steun mPawer en help ons een verschil maken!',
  route: 'Route',
  make_donation_placeholder_input: 'Voer het bedrag in',
};
