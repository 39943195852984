<template>
  <div class="notification-bar" :class="`type-${notification.item.type}`">
    <div class="notification-body" @click="goToLink">
      <div class="notification-icon">
        <v-icon color="#0dbff2"> mdi-paw </v-icon>
      </div>
      <div class="notification-details" @click="goToLink">
        <p class="notification-title">
          {{ notification.item.title }}
        </p>
        <p class="notification-text" v-html="notification.item.text"></p>
      </div>
      <div class="notification-close">
        <v-btn icon small @click="notification.close">
          <v-icon small> mdi-close </v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "push-notification-bar",
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  methods: {
    goToLink() {
      const { data } = this.notification.item;
      if (data && data.link) this.$router.push(data.link);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0 !important;
}
.notification {
  &-bar {
    background: rgba(243, 243, 243, 0.9);
    border-radius: 15px;
    padding-bottom: 0;
    // overflow: hidden;
    // box-shadow: rgb(0 0 0 / 20%) 0px 4px 27px;
  }

  &-body {
    padding: 1rem;
    display: flex;
    align-items: center;
    position: relative;
  }

  &-details {
    flex: 1;
    padding: 0 1rem;
  }
}
</style>
