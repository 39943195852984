import axios from "@/plugins/axios";
import moment from "moment";
const initialState = () => {
  return {
    notifications: [],
    page: 1,
    hasNext: true,
    loading: {
      all_notifications: false,
      single_notification: false,
    },
    notification: null,
  };
};
export default {
  namespaced: true,
  state: {
    ...initialState(),
  },
  getters: {
    notification_list(state) {
      let today = moment();
      return state.notifications.map((notification) => {
        const {
          created_at,
          body,
          readed,
          from_user: { avatar },
          notification_type,
          title,
        } = notification;
        return {
          ...notification,
          time: today.from(created_at),
          text: notification_type === "admin_push_notitcation" ? title : body,
          read: readed,
          image: avatar,
          title,
        };
      });
    },
  },
  mutations: {
    SET_PAGINATION(state, { hasNext, page }) {
      state.hasNext = hasNext;
      state.page = page;
    },
    SET_LOADING(state, { loading, type }) {
      state.loading[type] = loading;
    },
    SET_NOTIFICATIONS(state, payload) {
      state.notifications = [...state.notifications, ...payload];
    },
    MARK_ALL_NOTIFICATIONS_READ(state) {
      state.notifications = state.notifications.map((notification) => {
        return {
          ...notification,
          readed: 1,
        };
      });
    },
    CLEAR_ALL_NOTI_DATA(state) {
      const initial = { ...initialState() };
      for (const key in initial) {
        if (Object.hasOwnProperty.call(initial, key)) {
          state[key] = initial[key];
        }
      }
    },
    SET_SINGLE_NOTIFICATION(state, notification) {
      state.notification = notification;
    },
  },
  actions: {
    async FETCH_NOTIFICATIONS({ commit, state }, force) {
      try {
        if (force) commit("CLEAR_ALL_NOTI_DATA");

        commit("SET_LOADING", { type: "all_notifications", loading: true });
        const { page } = state;
        const resp = await axios.get(`/web/show-notifications/20?page=${page}`);
        const { notifications } = resp.data.data;

        if (notifications) {
          const { data, next_page_url } = notifications;
          commit("SET_PAGINATION", {
            hasNext: !!next_page_url,
            page: page + 1,
          });
          commit("SET_NOTIFICATIONS", data);
        }

        commit("SET_LOADING", { type: "all_notifications", loading: false });
      } catch (error) {
        commit("SET_LOADING", { type: "all_notifications", loading: false });
        console.log(error);
      }
    },
    async READ_SINGLE_NOTIFICATION({ commit }, id) {
      try {
        commit("SET_LOADING", { loading: true, type: "single_notification" });
        await axios.get(`/web/single-notification-read/${id}`);
        commit("SET_LOADING", { loading: false, type: "single_notification" });
      } catch (error) {
        commit("SET_LOADING", { loading: false, type: "single_notification" });
        console.log(error);
      }
    },
    async MARK_ALL_READ({ commit }) {
      try {
        commit("SET_LOADING", { loading: true, type: "all_notifications" });
        await axios.get(`/web/all-notifications-read`);
        commit("MARK_ALL_NOTIFICATIONS_READ");
        commit("SET_LOADING", { loading: false, type: "all_notifications" });
      } catch (error) {
        commit("SET_LOADING", { loading: false, type: "all_notifications" });
        console.log(error);
      }
    },
    async FETCH_SINGLE_NOTIFICATION({ commit }, id) {
      try {
        commit("SET_LOADING", { loading: true, type: "single_notification" });
        const resp = await axios.get(`/web/show-single-notification/${id}`);
        const { notification } = resp.data.data;
        commit("SET_SINGLE_NOTIFICATION", notification[0]);
        commit("SET_LOADING", { loading: false, type: "single_notification" });
      } catch (error) {
        commit("SET_LOADING", { loading: false, type: "single_notification" });
        console.log(error);
      }
    },
  },
};
