import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

// import en from "@/locales/eng";
// import ur from "@/locales/ur";

import en from "@/locales/english";
import fr from "@/locales/french";
import nl from "@/locales/dutch";

Vue.use(Vuetify);

let lang = localStorage.getItem("lang");

if (!lang) {
  localStorage.setItem("lang", "en");
  lang = "en";
}

export default new Vuetify({
  treeShake: true,
  defaultAssets: false,
  customVariables: ["@/scss/variables.scss"],
  lang: {
    locales: { en, fr, nl },
    current: lang,
    defaultLocale: "en",
  },
  default: "light",
  theme: {
    themes: {
      light: {
        primary: "#0DBFF2",
        secondary: "#5DEFCC",
        accent: "#8143F9",
        error: "#FF5F5F",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        golden: "#F6C15A",
        dark: "#333",
        "dark-blue": "#03354D",
        "light-pink": "#F39D88",
        "grey-light": "#F7F7F7",
        "light-bg": "#f4f4f4",
        "bg-yellow": "#fff7e7",
      },
    },
  },
});
