<template>
  <v-container class="filter-container pa-6">
    <h3 class="mb-3">{{ $vuetify.lang.t("$vuetify.add_filters") }}</h3>
    <template>
      <p>{{ $vuetify.lang.t("$vuetify.search_by_category") }}</p>
      <v-select
        outlined
        :items="sellerCategories"
        v-model="filter.category"
        item-text="name"
        item-value="id"
        label="Categories"
      ></v-select>
    </template>
    
    <p>{{ $vuetify.lang.t("$vuetify.search_by_zip_code") }}</p>
    <v-form v-model="isValid">
      <v-text-field
        v-model="filter.zipCode"
        outlined
        class="rounded-lg"
        type="text"
        dense
        :placeholder="$vuetify.lang.t('$vuetify.enter_zip_code')"
        ></v-text-field>
        <!-- :rules="[rules.zipCodeRule]" -->
    </v-form>

    <div class="slider-container">
      <div class="d-flex">
        <p>{{ $vuetify.lang.t("$vuetify.search_radius") }}</p>
        <v-spacer></v-spacer>
        <p class="km">{{ filter.radius }} km</p>
      </div>
      <v-slider
        v-model="filter.radius"
        step="10"
        min="0"
        max="100"
        thumb-label
        ticks
        hide-details
      ></v-slider>

      <div class="d-flex">
        <span class="sm">0 km</span>
        <v-spacer></v-spacer>
        <span class="sm">100 km</span>
      </div>
    </div>

    <div class="d-flex align-items-center justify-content-between mt-10">
      <app-button color="grey-light" :block="false" @click="clearFilter">{{
        $vuetify.lang.t("$vuetify.close")
      }}</app-button>
      <app-button :block="false" @click="applyFilter">{{
        $vuetify.lang.t("$vuetify.apply_filters")
      }}</app-button>
    </div>

    <div class="text-center mt-5">
      <v-btn text color="red" @click="clearAllFilter">
        {{ $vuetify.lang.t("$vuetify.clear_all_filters") }}</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const { mapMutations, mapActions, mapState } = createNamespacedHelpers("vet");

import { zipCodeRule } from "@/helpers/form-rules";
import MixDevice from "@/mixin/mix-device";

import { postcodeValidator } from "postcode-validator";

export default {
  name: "vet-filters",
  mixins: [MixDevice],
  data() {
    return {
      filter: {
        zipCode: "",
        radius: 20,
        category: null
      },
      isValid: false,
      sellerCategories: [],
    };
  },
  mounted() {
    this.getSellerCategories().then((response) => {
      this.sellerCategories = response.data.data.data;
    })
  },
  computed: {
    ...mapState(["currentLocationAddress"]),
    rules() {
      return {
        zipCodeRule,
      };
    },
  },
  methods: {
    ...mapMutations(["SET_FILTERS"]),
    ...mapActions(["FETCH_VET_LIST"]),
    ...popupModule.mapMutations(["CLEAR_SHEET"]),
    ...popupModule.mapActions(["getSellerCategories"]),
    clearFilter() {
      this.CLEAR_SHEET();
    },
    isValidZip() {
      if (!this.filter.zipCode) return true;
      const { country_code } = this.currentLocationAddress;
      return postcodeValidator(this.filter.zipCode, country_code);
    },
    applyFilter() {
      if (!this.isValidZip()) {
        window.showAlert("Please Enter valid Zip Code.", "Invalid Zip Code");
        return;
      }

      this.SET_FILTERS(this.filter);
      this.FETCH_VET_LIST();
      this.CLEAR_SHEET();
    },
    clearAllFilter() {
      this.filter = {
        zipCode: "",
        radius: 20,
      };
      this.SET_FILTERS(this.filter);
      this.CLEAR_SHEET();
      this.FETCH_VET_LIST();
    },
  },
  created() {
    this.filter = this.$store.state.vet.filters;
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  overflow: hidden;

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }

  .slider-container {
    margin-bottom: 4rem;
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 0;
    }

    p.km {
      font-size: 18px;
    }

    span.sm {
      font-size: 10px;
      line-height: 22px;
      color: #000000;
      opacity: 0.5;
    }
  }
}
</style>
