<template>
  <v-expand-transition>
    <v-bottom-sheet
      v-model="openBottomSheet"
      :key="reRender"
      :scrollable="true"
      :persistent="true"
      :fullscreen="this.sheetOptions.fullscreen"
    >
      <div
        class="relative white"
        :class="!isFullScreen ? 'rounded-t-xl' : ''"
        style="overflow-y:auto;"
        id="sheet-content"
        v-scroll.self="onScroll"
      >
        <v-btn
          absolute
          dark
          fab
          color="#F0F0F0"
          height="25"
          width="25"
          style="top:10px;right:10px;"
          @click="CLEAR_SHEET"
          v-if="!isFullScreen"
        >
          <v-icon color="#333" small>mdi-close</v-icon>
        </v-btn>

        <v-app-bar v-if="sheetOptions.showAppBar" flat color="#fff">
          <v-btn icon @click="CLEAR_SHEET">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-toolbar-title v-if="sheetOptions.appBarTitle">{{
            sheetOptions.appBarTitle
          }}</v-toolbar-title>
        </v-app-bar>

        <component :is="comp" v-bind="componentProps" />
      </div>
    </v-bottom-sheet>
  </v-expand-transition>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("popup");

import Filter from "@/components/filter/filter";
import FeedComments from "@/components/feed/feed-comments";
import VetFilter from "@/components/vet/vet-filter";
import VetSearchFilter from "@/components/vet/vet-search-location";
import Advertisement from "@/components/advertisement/advertisement-card";

export default {
  data() {
    return {
      isScrolled: false,
      reRender: false,
    };
  },
  computed: {
    ...mapState([
      "showSheet",
      "sheetComponent",
      "sheetOptions",
      "componentProps",
    ]),
    comp() {
      return this.allComponents[this.sheetComponent];
    },
    allComponents() {
      return {
        filter: Filter,
        "feed-comments": FeedComments,
        "vet-filter": VetFilter,
        "vet-search-location": VetSearchFilter,
        advertisement: Advertisement,
      };
    },
    isFullScreen() {
      const { fullscreen } = this.sheetOptions;
      return fullscreen;
    },
    openBottomSheet: {
      get() {
        return this.showSheet;
      },
      set(val) {
        if (!val) {
          this.CLEAR_SHEET();
        }
      },
    },
  },
  methods: {
    ...mapMutations(["CLEAR_SHEET"]),
    onScroll(e) {
      if (this.isFullScreen) {
        document.querySelector(".v-bottom-sheet").style.maxHeight = "100%";
        return;
      }
      const scroll = e.target.scrollTop;
      scroll > 1
        ? (document.querySelector(".v-bottom-sheet").style.maxHeight = "100%")
        : (document.querySelector(".v-bottom-sheet").style.maxHeight = "85%");
    },
  },
  created() {},
  watch: {
    openBottomSheet(val) {
      if (!val) setTimeout(() => (this.reRender = !this.reRender), 1500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
