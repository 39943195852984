export default {
  mainHeading: "Termes et conditions",
  heading1: "Cookies",
  heading2: "Licence",
  mainHeadingContent:
    "Bienvenue sur mpawer.app ! Ces termes et conditions décrivent les règles et réglementations d'utilisation du site Web de Mpawer, situé à l'adresse https://mpawer.app/. En accédant à ce site Web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser mpawer.app si vous n'acceptez pas que l'ensemble de la terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité et à l'avis de non-responsabilité et à tous les accords : « Client », « Vous » et « Votre » font référence à vous. , la personne se connecte sur ce site et se conforme aux termes et conditions de la Société. 'La Société', 'Nous-mêmes', 'Nous', 'Notre' et 'Nous' font référence à notre Société. « Partie », « Parties » ou « Nous » désignent à la fois le Client et nous-mêmes. Tous les termes se réfèrent à l'offre, à l'acceptation et à la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée dans le but exprès de répondre aux besoins du Client en ce qui concerne la fourniture des services déclarés de la Société, conformément à et sous réserve de la législation en vigueur aux Pays-Bas. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, en majuscules et/ou il/elle ou ils, sont considérés comme interchangeables et donc comme faisant référence à ceux-ci.",
  heading1Content:
    "Nous employons l'utilisation de cookies. En accédant à mpawer.app, vous avez accepté d'utiliser des cookies en accord avec la politique de confidentialité de Mpawer. La plupart des sites Web interactifs utilisent des cookies pour nous permettre de récupérer les détails de l'utilisateur pour chaque visite. Les cookies sont utilisés par notre site Web pour activer la fonctionnalité de certaines zones afin de faciliter la tâche des personnes visitant notre site Web. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.",
  heading2Content:
    "Sauf indication contraire, Mpawer et/ou ses concédants détiennent les droits de propriété intellectuelle pour tout le matériel sur mpawer.app. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez y accéder à partir de mpawer.app pour votre usage personnel, sous réserve des restrictions définies dans les présentes conditions générales.",
};
