<template>
  <div
    v-if="!petCatLoading"
    class="category--list"
    :style="`flex-wrap:${wrap ? 'wrap' : 'no-wrap'};`"
  >
    <div
      class="category--list-item"
      v-for="category in petCategoryList"
      @click="toggleCategory(category)"
      v-ripple
      :key="category.id"
      :class="{ active: selected === category.id }"
    >
      <v-avatar size="36" class="category--list-item-image">
        <img :src="category.image" :alt="category.name" />
      </v-avatar>

      <p
        class="category--list-item-name"
        v-html="
          lang == 'en'
            ? category.name.replace(' ', '&nbsp;')
            : lang == 'nl'
            ? category.nt_name.replace(' ', '&nbsp;')
            : category.fr_name.replace(' ', '&nbsp;')
        "
      ></p>
    </div>
    <!-- <div
      class="category--list-item"
      @click="toggleCategory({ id: 'other', name: 'others' })"
      :class="{ active: selected === 'other' }"
      v-ripple
    >
      <p class="category--list-item-name py-2">others</p>
    </div> -->
  </div>

  <v-skeleton-loader
    class="mb-2"
    v-else
    type="list-item-avatar,divider"
  ></v-skeleton-loader>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("pet");
export default {
  props: ["value", "wrap", "multiple"],
  name: "pet-category-list",
  data() {
    return {
      selected: this.value,
      selectedObj: null,
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    ...mapState(["petCategoryList", "petCatLoading"]),
    filteredCategory() {
      if (this.petCategoryList.length) {
        return this.petCategoryList.filter((item) => {
          if (this.lang == "en" && item.name) return item;
          if (this.lang == "nl" && item.nt_name) return item;
          if (this.lang == "fr" && item.fr_name) return item;
        });
      } else {
        return this.petCategoryList
      }
    },
  },
  methods: {
    ...mapActions(["FETCH_PET_CATEGORY_LIST"]),
    toggleCategory(category) {
      if (this.selected == category.id) {
        this.selected = "";
        this.selectedObj = null;
      } else {
        this.selected = category.id;
        this.selectedObj = category;
      }
      this.$emit("input", this.selected);
      this.$emit("selected", this.selectedObj);
    },
  },
  async created() {
    if (this.petCategoryList.length === 0) {
      await this.FETCH_PET_CATEGORY_LIST();
      if (this.value) {
        const index = this.petCategoryList.findIndex(
          (cat) => cat.id == this.value
        );

        if (index !== -1) {
          this.selectedObj = this.petCategoryList[index];
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.category--list {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  overflow-x: auto;
  padding-left: 5px;

  &-item {
    border: 0.5px solid #cccccc;
    border-radius: 90px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: 0.51rem;
    cursor: pointer;

    &-image {
      margin-left: -5px;
    }
    &-name {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin: 0;
      padding: 0 0.9rem;
      text-transform: capitalize;
    }

    &.active {
      background: #0dbff2;
      color: #FFFFFF;
      
      p{ font-weight: bold; }
    }
  }
}
</style>
