import axios from 'axios';
import get from 'lodash/get';
import { API_END_POINT } from '@/config';

const service = axios.create({
  baseURL: API_END_POINT,
});

service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    let token = response.headers['x-amzn-remapped-authorization'];
    if (token) {
      localStorage.setItem('token', token);
    }
    return response;
  },
  (error) => {
    let errorMsg = get(
      error,
      'response.data.message',
      'Something Went Wrong...!'
    );

    const errorsArr = [
      'token not provided',
      'token has expired',
      'your token has expired. Please, login again.',
      'token signature could not be verified.',
      'user not found',
      'the token has been blacklisted',
    ];

    if (errorsArr.includes(errorMsg.toLowerCase())) {
      localStorage.removeItem('token');
      window.location.reload();
    }

    if (errorMsg === 'Validation Error') {
      const errorObj = { ...error.response.data };
      delete errorObj.message;

      for (const key in errorObj) {
        const element = errorObj[key];
        const elKeys = Object.keys(element);

        if (elKeys.length) {
          errorMsg = element[elKeys[0]][0];
        }
      }
    }

    window.showAlert(errorMsg, 'Some Error');
    return Promise.reject(error);
  }
);

export default service;
