import axios from "@/plugins/axios";
import { calculateAge } from "@/helpers";
import moment from "moment";

const addPetAge = (pets, list = false) => {
  const dob = moment()
    .subtract(2, "months")
    .format("YYYY-MM-DD");

  if (!list)
    return pets.map(pet => {
      const { date_of_birth } = pet;
      return {
        ...pet,
        age: calculateAge(date_of_birth ? date_of_birth : dob)
      };
    });

  return pets.map(pet => {
    const { animal } = pet;
    const { date_of_birth } = animal;
    animal.age = calculateAge(date_of_birth ? date_of_birth : dob);
    return {
      ...pet,
      animal: {
        ...animal
      }
    };
  });
};

export default {
  async FETCH_PET_LIST({ commit, state }) {
    try {
      commit("SET_LOADING", true);
      const { page, filter } = state;
      const resp = await axios.post(`/web/show-all-animals?page=${page}`, {
        ...filter
      });
      const animals = resp.data.data;
      const { data, next_page_url } = animals;

      commit("SET_PET_PAGINATION", {
        hasNext: !!next_page_url,
        page: page + 1
      });

      commit("SET_PETS_LIST", addPetAge(data));
      commit("SET_LOADING", false);
    } catch (error) {
      console.log(error);
      commit("SET_LOADING", false);
    }
  },
  async FETCH_FEATURED_PETS({ commit }) {
    try {
      commit("SET_FEATURED_LOADING", true);
      const resp = await axios.get("/web/show-all-featured-animals");
      const { animals } = resp.data.data;
      if (animals) {
        commit("SET_FEATURED_PETS", addPetAge(animals));
      }
      commit("SET_FEATURED_LOADING", false);
    } catch (error) {
      console.log(error);
      commit("SET_FEATURED_LOADING", false);
    }
  },
  async FETCH_ACTIVE_PET_DETAILS({ commit }, pet_id) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.get(`/web/show-single-animal/${pet_id}`);
      const { animal } = resp.data.data;
      if (typeof animal.meta_data === "string") {
        animal.meta_data = JSON.parse(animal.meta_data);
      }
      commit("SET_ACTIVE_PET_DETAIL", {
        ...animal,
        age: calculateAge(animal.date_of_birth || "2021-08-11")
      });
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async FETCH_PUBLIC_ACTIVE_PET_DETAILS({ commit }, pet_id) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.get(`/public/show-single-animal/${pet_id}`);
      console.log('REsponse: ', resp);
      const animal = resp.data.data;
      if (typeof animal.meta_data === "string") {
        animal.meta_data = JSON.parse(animal.meta_data);
      }
      commit("SET_ACTIVE_PET_DETAIL", {
        ...animal,
        age: calculateAge(animal.date_of_birth || "2021-08-11")
      });
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async FETCH_PET_CATEGORY_LIST({ commit }) {
    try {
      commit("SET_CAT_LOADING", true);
      const resp = await axios.get("/web/show-all-categories");
      const { data } = resp.data;
      commit("SET_PET_CATEGORY", data);
      commit("SET_CAT_LOADING", false);
    } catch (error) {
      console.log(error);
      commit("SET_CAT_LOADING", false);
    }
  },
  async Fetch_PET_RACE({ commit }, cat_id) {
    try {
      commit("SET_RACE_LOADER", true);
      const resp = await axios.get(`/web/show-all-sub-categories/${cat_id}`);
      const { data } = resp.data;
      commit("SET_PET_RACE", data);
      commit("SET_RACE_LOADER", false);
    } catch (error) {
      console.log(error);
      commit("SET_RACE_LOADER", false);
    }
  },
  async FETCH_TINDER_LISTING({ commit, state }) {
    try {
      commit("SET_TINDER_LOADING", true);
      const { tinderPage } = state;
      const resp = await axios.get(
        `/user/show-tinder-animals/5?page=${tinderPage}`
      );
      const { data, next_page_url } = resp.data.data;
      commit("SET_TINDER_LISTING", addPetAge(data));
      commit("SET_TINDER_PAGINATION", {
        page: tinderPage + 1,
        hasNext: !!next_page_url
      });
      commit("SET_TINDER_LOADING", false);
      return true;
    } catch (error) {
      console.log(error);
      commit("SET_TINDER_LOADING", false);
      return false;
    }
  },
  async PET_FAVORITE_HANDLER({ commit, state }, id) {
    try {
      const index = state.petList.findIndex(pet => pet.id === id);
      if (index !== -1) {
        const pet = { ...state.petList[index] };
        pet.liked = !pet.liked;
        commit("UPDATE_PET_LIST", pet);
      }
      await axios.get(`/user/like-animal/${id}`);
    } catch (error) {
      console.log(error);
    }
  },
  async PET_DISLIKE_HANDLER(ctx, id) {
    try {
      await axios.get(`/user/dislike-animal/${id}`);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async FETCH_USER_CUTE_LIST({ commit }) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.get("/user/show-all-user-like-animals");
      const { data } = resp.data;
      commit("SET_PET_LIST", { type: "cuteList", list: addPetAge(data) });
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
    }
  },
  async FETCH_ADOPTION_REQUEST_LIST({ commit }) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.get("/user/show-all-user-pets");
      const { co_owned, owned } = resp.data.data;
      commit("SET_PET_LIST", {
        type: "adoptionList",
        list: addPetAge(owned, true)
      });
      commit("SET_PET_LIST", {
        type: "coOwnedList",
        list: addPetAge(co_owned, true)
      });
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
    }
  },
  async MAKE_ADOPTION_REQUEST({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      await axios.post("/user/addoption-pet-request", payload);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      throw new Error(error);
    }
  },
  async FETCH_PET_ADVERTISEMENT({ commit }) {
    try {
      commit("PET_AD_LOADING", true);
      const resp = await axios.get("/auth/show-adoption-advertisment");
      const { data } = resp.data;
      commit("SET_ADVERTISEMENT", data);
      commit("SET_AD_TIMER", data.add_time);
      commit("PET_AD_LOADING", false);
      return true;
    } catch (error) {
      commit("PET_AD_LOADING", false);
      console.log(error);
      return false;
    }
  }
};
