<template>
  <v-dialog
    v-model="dialog"
    :hide-overlay="modalOptions.hideOverlay"
    :overlay-color="modalOptions.overlayColor"
    :overlay-opacity="modalOptions.overlayOpacity"
    :persistent="modalOptions.persistent"
    :fullscreen="modalOptions.fullscreen"
    :max-width="modalOptions.maxWidth"
    transition="dialog-bottom-transition"
  >
    <component :is="component" v-bind="componentProps" />
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("popup");

import modalComponents from "./modal-components";
export default {
  name: "global-popup",
  components: {
    ...modalComponents,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["show", "component", "componentProps", "modalOptions"]),
    dialog: {
      set(show) {
        if (!show) {
          this.CLEAR_POPUP();
        }
      },
      get() {
        return this.show;
      },
    },
  },
  methods: {
    ...mapMutations(["SET_POPUP", "CLEAR_POPUP"]),
  },
};
</script>

<style lang="scss" scoped></style>
