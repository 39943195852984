import moment from "moment";
import axios from "@/plugins/axios";
import router from "@/router";
import { API_END_POINT2 } from "@/config";
import UploadService from "@/plugins/upload-service";

export default {
  async FETCH_FEED({ commit, state }) {
    try {
      const { page, limitPerPage, adPosition } = state;
      commit("SET_PET_LOADING", true);
      const resp = await axios.get(
        `/web/show-all-post/${adPosition}/${limitPerPage}?page=${page}`
      );
      const { data, next_page_url } = resp.data.data;

      const feeds = data || [];
      feeds.forEach((feed) => {
        const { title, created_at } = feed;
        if (title) {
          feed.is_ad = true;
        }
        feed.time = moment(created_at).format("MMMM DD");
        commit("SET_FEED_LIST", feed);
      });

      commit("SET_FEED_PAGINATION", {
        hasNext: !!next_page_url,
        page: page + 1,
      });

      commit("SET_PET_LOADING", false);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async FETCH_SINGLE_POST({ commit }, post_id) {
    try {
      commit("SET_PET_LOADING", true);
      const resp = await axios.get(`/web/show-single-post/${post_id}`);
      const { post } = resp.data.data;

      post.time = moment(post.created_at).format("MMMM DD");
      commit("SET_SINGLE_POST", post);
      commit("SET_PET_LOADING", false);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async FETCH_SINGLE_POST_PUBLIC({ commit }, post_id) {
    try {
      commit("SET_PET_LOADING", true);
      const resp = await axios.get(`/public/show-single-post/${post_id}`);
      const { post } = resp.data.data;

      post.time = moment(post.created_at).format("MMMM DD");
      commit("SET_SINGLE_POST", post);
      commit("SET_PET_LOADING", false);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async TOGGLE_FEED_POST_LIKE({ commit, state }, { post_id, list }) {
    try {
      commit("SET_PET_LOADING", false);

      if (list) {
        const allPosts = [...state.feeds];
        const index = allPosts.findIndex((post) => post.id === post_id);
        if (index != -1) {
          const post = allPosts[index];

          if (post.liked) {
            post.liked = false;
            post.total_like = post.total_like - 1;
          } else {
            post.liked = true;
            post.total_like = post.total_like + 1;
          }
          allPosts[index] = { ...post };
          commit("SET_FEED", [...allPosts]);
        }
      } else {
        const post = { ...state.currentPost };
        if (post.liked) {
          post.liked = false;
          post.total_like = post.total_like - 1;
        } else {
          post.liked = true;
          post.total_like = post.total_like + 1;
        }
        commit("SET_SINGLE_POST", { ...post });
      }

      await axios.get(`/web/like-post/${post_id}`);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async FETCH_POST_COMMENTS({ commit }, payload) {
    try {
      commit("SET_COMMENTS_LOADING", true);
      const resp = await axios.get(`/web/show-like-comment-post/${payload}`);
      const { comments } = resp.data.data;
      comments.forEach((comment) => {
        const commentTime = comment.comment.created_at;
        comment.comment.created_at = moment(commentTime).toNow(true);
      });
      commit("SET_COMMENTS", comments);
      commit("SET_COMMENTS_LOADING", false);
    } catch (error) {
      commit("SET_COMMENTS_LOADING", false);
      console.log(error);
    }
  },
  async FETCH_POST_COMMENTS_PUBLIC({ commit }, payload) {
    try {
      commit("SET_COMMENTS_LOADING", true);
      const resp = await axios.get(`/public/show-like-comment-post/${payload}`);
      const { comments } = resp.data.data;
      comments.forEach((comment) => {
        const commentTime = comment.comment.created_at;
        comment.comment.created_at = moment(commentTime).toNow(true);
      });
      commit("SET_COMMENTS", comments);
      commit("SET_COMMENTS_LOADING", false);
    } catch (error) {
      commit("SET_COMMENTS_LOADING", false);
      console.log(error);
    }
  },
  async ADD_FEED_POST_COMMENT(
    { commit, state, rootState },
    { comment, post_id }
  ) {
    try {
      const resp = await axios.get(`/web/comment-post/${post_id}/${comment}`);
      const { postLikeComment } = resp.data.data;

      const { user } = rootState.user;
      const commentTime = moment(postLikeComment.created_at);
      postLikeComment.created_at = moment(commentTime).toNow(true);
      const postComment = {
        comment: postLikeComment,
        user,
      };
      const allComments = [...state.comments];
      const post = { ...state.currentPost };
      post.total_comment = post.total_comment + 1;

      allComments.push(postComment);
      commit("SET_SINGLE_POST", { ...post });
      commit("SET_COMMENTS", allComments);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async CREATE_POST({ commit }, payload) {
    try {
      commit("SET_PET_LOADING", true);

      const medias = payload.medias;
      const fd = new FormData();
      for (let i = 0; i < medias.length; i++) {
        fd.append(`media[${i}]`, medias[i]);
      }

      const mediaResp = await UploadService.post("/add-media", fd, {
        onUploadProgress(progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          commit("SET_POST_PROGRESS", percentCompleted);
        },
      });
      const media = mediaResp.data.data;
      delete payload.medias;
      payload.file_name = media;
      const resp = await axios.post("/web/add-post", payload);
      const { post } = resp.data.data;
      router.push(`/post/${post.id}`);
      commit("SET_POST_PROGRESS", 0);
      commit("SET_PET_LOADING", false);
    } catch (error) {
      console.log(error);
      commit("SET_PET_LOADING", false);
    }
  },
  async FETCH_MY_POST({ commit, state }) {
    try {
      commit("SET_PET_LOADING", true);
      const { myPostPage } = state;
      const resp = await axios.get(
        `/web/show-user-all-posts/10?page=${myPostPage}`
      );
      const { data, next_page_url } = resp.data.data;
      commit("SET_MY_POST", data);
      commit("SET_MY_POST_PAGINATION", {
        page: myPostPage + 1,
        hasNext: !!next_page_url,
      });
      commit("SET_PET_LOADING", false);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },
  async DELETE_USER_POST({ commit, state }, { post_id, feedList }) {
    try {
      commit("SET_DELETE_LOADING", true);
      await axios.delete(`/web/delete-post/${post_id}`);

      if (feedList) {
        const allFeeds = [...state.feeds];
        const index = allFeeds.findIndex((f) => f.id === post_id);
        if (index !== -1) {
          allFeeds.splice(index, 1);
          commit("SET_FEED", [...allFeeds]);
        }
      }

      commit("SET_DELETE_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_DELETE_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async REPORT_FEED({ commit }, payload) {
  
    try {
     
      if (payload.post_id) {
        await axios.post("user/block-post", payload);
      } else {
        await axios.post("user/block-user", payload);
      }
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  },

  async REFETCH_FEED({ commit, dispatch }) {
    await commit("CLEAR_FEED");
    dispatch("FETCH_FEED");
  },
  async FETCH_FACEBOOK({ commit }, payload) {
    
   
    try {
      
        commit("SET_PET_LOADING", true);

      const resp = await axios.get(`/web/check-facebook-connected`);
      const { status } = resp.data.data;
      commit("GET_FACEBOOK_STATUS", status);
      //sending post to facebook
      try {
        commit("SET_PET_LOADING", true);
       
        const resp = await axios.post(`/web/post-to-facebook-page`, payload);
        const { status } = resp.data.data;
        commit("POST_IN_FACEBOOK", status);
      } catch (error) {
        commit("SET_PET_LOADING", false);
        console.log(error);
      }
      //
      commit("SET_PET_LOADING", false);
    } catch (error) {
      commit("SET_PET_LOADING", false);
      try {
        commit("SET_PET_LOADING", true);
        const resp = await axios.get(`${API_END_POINT2}/web/auth/facebook`);
        const { status } = resp.data;
        window.open(resp.data, '_self');

        commit("FACEBOOK_AUTH", status);
        commit("SET_PET_LOADING", false);
      } catch (error) {
        commit("SET_PET_LOADING", false);
        console.log(error);
      }
    
      
      console.log(error);
    }
  },
  async MAKE_POST_PUBLIC ({ commit }, payload) {
    try {
      commit("SET_PET_LOADING", true);
      await axios.post("web/make-post-public", payload);
      commit("SET_PET_LOADING", false);
      
      return true;
    } catch (error) {
      commit("SET_PET_LOADING", false);
      console.log(error);
    }
  }
};
