<template>
  <v-card mx-width="90%" style="border-radius:10px;">
    <v-btn
      absolute
      dark
      fab
      color="#F0F0F0"
      @click="$store.commit('popup/CLEAR_POPUP')"
      height="25"
      width="25"
      style="top:10px;right:10px;"
    >
      <v-icon color="#333" small>mdi-close</v-icon>
    </v-btn>

    <div class="pet-container">
      <v-img src="@/assets/svg/frame.svg"></v-img>

      <div class="pet-content">
        <v-img
          :src="pet.media[0]"
          alt=""
          class="pet-img mx-auto"
          conatin
          height="80px"
          width="80px"
          center
        />

        <p>Wohooo! Your request to adopt {{ pet.name }} has been accepted</p>

        <div class="see-detail">
          <span> See Details </span>
        </div>

        <div class="icons mt-3">
          <v-btn
            height="64px"
            color="#F6C15A1a"
            class="elevation-0"
            @click="contactHandler('phone')"
          >
            <v-icon color="primary">mdi-phone</v-icon>
          </v-btn>

          <v-btn
            height="64px"
            color="#F6C15A1a"
            class="elevation-0"
            @click="contactHandler('email')"
          >
            <v-icon color="primary">mdi-email</v-icon>
          </v-btn>

          <v-btn
            height="64px"
            color="#F6C15A1a"
            class="elevation-0"
            @click="contactHandler('location')"
          >
            <v-icon color="primary">mdi-map-marker</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { DEVICE } from "@/constants";
export default {
  name: "pet-popup",
  props: {
    pet: {
      type: Object,
    },
  },
  computed: {
    PLATFORM() {
      if (!window.cordova) return false;
      return window.device.platform;
    },
  },
  methods: {
    openInAppBrowser(url, options = "") {
      let target = "_system";
      if (!window.cordova) target = "_blank";
      window.open(url, target, options);
    },
    contactHandler(type) {
      const { latitude, longitude, seller } = this.pet;
      if (type === "phone") {
        const { contact } = seller;
        if (!contact) return;
        const url = `tel:${contact}`;

        this.openInAppBrowser(url);
      } else if (type === "email") {
        const { email } = seller;
        if (!email) return;
        const url = `mailto:${email}`;

        this.openInAppBrowser(url);
      } else {
        let url = `https://www.google.com/maps/?q=${latitude},${longitude}`;
        if (window.cordova) {
          url = `maps://?q=${latitude},${longitude}`;
          if (this.PLATFORM === DEVICE.ANDROID) {
            url = `geo:0,0?q=${latitude},${longitude}`;
          }
        }

        this.openInAppBrowser(url, "location=yes");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pet-container {
  position: relative;
  .pet-content {
    padding: 1rem 2rem;
    width: 100%;
    margin-top: -60%;

    .pet-img {
      border-radius: 10px;
    }

    > p {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #000000;
      margin: 1rem auto;
      max-width: 80%;
    }

    .see-detail {
      text-align: center;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: #3333335a;
      }

      span {
        font-size: 12px;
        line-height: 22px;
        color: #000000a5;
        display: inline-block;
        background: #fff;
        position: relative;
        z-index: 111;
        padding: 0 1rem;
      }
    }
    .icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
