// import cordovaProvider from "./cordova-oauth";
// import webProvider from "./web-oauth";

let defaultProvider = () => import("./web-oauth.js");

if ("cordova" in window) {
  defaultProvider = () => import("./cordova-oauth.js");
}

export default defaultProvider();
