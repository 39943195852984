import router from "@/router";
import Vue from "vue";
import Store from "../store/index.js";
export default () => {
  const push = window.PushNotification.init({
    android: {
      senderID: "487242910148",
      sound: true,
      vibrate: true,
    },
    ios: {
      alert: "true",
      badge: "true",
      sound: "true",
    },
  });

  push.on("registration", (data) => {
    localStorage.setItem("upload-token", false);
    const oldToken = localStorage.getItem("device_token");
    const token = data.registrationId;

    if (oldToken !== token) {
      localStorage.setItem("device_token", data.registrationId);
      localStorage.setItem("upload-token", true);
    }
  });

  push.on("notification", (data) => {
    const { additionalData, message, title } = data;
    const { foreground, link } = additionalData;
    if (!foreground) {
      router.push(link);
    }

    Vue.notify({
      group: "push-notification",
      text: message.substr(0, 20),
      title,
      type: "success",
      data: { link },
      duration: 5000,
    });

    Store.dispatch("notification/FETCH_NOTIFICATIONS", true);
    // window.showAlert(`${message}`, title);
  });

  push.on("error", (e) => {
    window.showAlert(e.message, "Notification Error");
  });
};
