export default {
  mainHeading: "Politique de confidentialité",
  heading1: "Consentement",
  heading2: "Informations que nous collectons",
  mainHeadingContent:
    "Sur mpawer.app, accessible depuis https://mpawer.app/, l'une de nos principales priorités est la confidentialité de nos visiteurs. Ce document de politique de confidentialité contient des types d'informations qui sont collectées et enregistrées par mpawer.app et comment nous les utilisons. Si vous avez des questions supplémentaires ou souhaitez plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter. Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site Web en ce qui concerne les informations qu'ils ont partagées et/ou collectées sur mpawer.app. Cette politique ne s'applique pas aux informations collectées hors ligne ou via des canaux autres que ce site Web. Notre politique de confidentialité a été créée à l'aide du générateur gratuit de politique de confidentialité.",
  heading1Content:
    "En utilisant notre site Web, vous consentez par la présente à notre politique de confidentialité et acceptez ses conditions.",
  heading2Content:
    "Les informations personnelles que vous êtes invité à fournir, et les raisons pour lesquelles vous êtes invité à les fournir, vous seront précisées au moment où nous vous demanderons de fournir vos informations personnelles. Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires vous concernant telles que votre nom, votre adresse e-mail, votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous pouvez nous envoyer, et toute autre information que vous pouvez choisir de fournir. Lorsque vous créez un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que le nom, le nom de l'entreprise, l'adresse, l'adresse e-mail et le numéro de téléphone.",
};
