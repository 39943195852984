<template>
  <div class="pt-10 search-container">
    <div>
      <div class="map-input">
        <gmap-autocomplete
          :placeholder="$vuetify.lang.t('$vuetify.enter_your_address')"
          @place_changed="getSearchedAddress"
        >
        </gmap-autocomplete>
      </div>

      <div
        class="d-flex align-items-center mx-5 mt-5"
        @click="getUserLocation"
        v-ripple
      >
        <img src="@/assets/svg/marker.svg" class="mr-3" alt="" />

        <p class="primary--text mb-0">
          {{ $vuetify.lang.t("$vuetify.use_my_current_location") }}
        </p>
      </div>
    </div>

    <p class="px-4" :class="isIOS ? 'mb-8' : ''">
      <app-button color="grey-light" @click="CLEAR_SHEET">{{
        $vuetify.lang.t("$vuetify.cancel")
      }}</app-button>
    </p>
  </div>
</template>

<script>
import { showAlert } from "@/helpers";

import { createNamespacedHelpers, mapState } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const vetModule = createNamespacedHelpers("vet");
const userModule = createNamespacedHelpers("user");

import MixDevice from "@/mixin/mix-device";
export default {
  name: "vet-search-location",
  mixins: [MixDevice],
  data() {
    return {};
  },
  computed: {
    ...mapState(["isCordova"]),
    ...userModule.mapState(["user"]),
  },
  methods: {
    ...popupModule.mapMutations(["CLEAR_SHEET"]),
    ...vetModule.mapMutations([
      "SET_CENTER_POSITION",
      "PUSH_LOCATION_MARKER",
      "SET_LOCATION_ADDRESS",
      "SET_CURRENT_LOCATION",
      "SET_LOCATION_ADDED",
    ]),
    ...vetModule.mapActions(["FETCH_VET_LIST"]),
    getSearchedAddress(place) {
      console.log(this.user);
      let country_code = this.user.country_code;
      const { geometry, name, formatted_address, address_components } = place;

      address_components.forEach((add) => {
        let isCountry = add.types.includes("country");
        if (isCountry) {
          country_code = add.short_name;
        }
      });
      const position = {
        lat: geometry.location.lat(),
        lng: geometry.location.lng(),
      };

      this.addUserAddress({ name, position, formatted_address, country_code });
    },

    addUserAddress({ name, position, formatted_address, country_code }) {
      this.SET_CENTER_POSITION(position);
      this.SET_CURRENT_LOCATION(position);
      this.SET_LOCATION_ADDRESS({
        name,
        formatted_address,
        position,
        country_code,
      });

      this.PUSH_LOCATION_MARKER({
        position,
        icon: {
          url: require("@/assets/svg/marker.svg"),
        },
      });
      this.SET_LOCATION_ADDED(true);
      this.FETCH_VET_LIST();
      this.CLEAR_SHEET();
    },

    getUserLocation() {
      if (window.cordova) {
        this.getLocationFromMob();
      } else {
        this.getLocationFromWeb();
      }
    },
    getLocationFromWeb() {
      navigator.geolocation.getCurrentPosition((res) => {
        const position = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
        this.setUserPosition(position);
      });
    },
    getLocationFromMob() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const { latitude, longitude } = pos.coords;
          const position = {
            lat: latitude,
            lng: longitude,
          };
          this.setUserPosition(position);
        },
        (err) => {
          showAlert(err.message, "Error...!");
          this.setDefaultLocation();
          console.log(err);
        }
      );
    },

    setDefaultLocation() {
      this.addUserAddress({
        name: "Antwerp, Belgium",
        formatted_address: "Antwerp, Belgium",
        country_code: "Br",
        position: {
          lat: 36.7092,
          lng: 9.9756,
        },
      });
    },

    setUserPosition(position) {
      const { lat, lng } = position;
      const geocoder = new window.google.maps.Geocoder();
      const latlng = new window.google.maps.LatLng(lat, lng);

      geocoder.geocode({ latLng: latlng }, (results, status) => {
        if (status == window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const country_code = this.extractCountryCode(
              results[0].address_components
            );
            this.SET_LOCATION_ADDRESS({
              name: results[0].formatted_address,
              formatted_address: results[0].formatted_address,
              position,
              country_code,
            });
          } else {
            window.showAlert("Address Not Found", "Error...!");
          }
        } else {
          window.showAlert(
            "Geocoder failed due to: " + status,
            "Location Not Found."
          );
        }
      });

      this.PUSH_LOCATION_MARKER({
        position,
        icon: {
          url: require("@/assets/svg/marker.svg"),
        },
      });

      this.SET_CURRENT_LOCATION(position);
      this.SET_CENTER_POSITION(position);
      this.FETCH_VET_LIST();
      this.SET_LOCATION_ADDED(true);
      this.CLEAR_SHEET();
    },

    extractCountryCode(address_components) {
      let country_code = this.user.country_code;
      address_components.forEach((add) => {
        const isCountry = add.types.includes("country");
        if (isCountry) {
          country_code = add.short_name;
        }
      });

      return country_code;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  &::v-deep input {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
