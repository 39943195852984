import axios from '@/plugins/axios';

// belgium cords
const defaultCords = {
  lat: 50.5039,
  lng: -4.4699,
};

export default {
  namespaced: true,
  state: {
    vetList: [],
    locationMarkers: [],

    loading: false,
    locationAdded: false,
    center: defaultCords,
    currentLocationCords: defaultCords,

    currentLocationAddress: null,

    filters: {
      radius: 20,
      zipCode: '',
    },

    vet: null,
    seller: null,
    sellerPosts: [],
    sellerPets: [],
    sellerAllPosts: {},
    sellerAllPets: {},
    vetLoading: false,
    reUpdate: false,
  },
  getters: {},
  mutations: {
    SET_VET_LIST(state, payload) {
      state.vetList = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_FILTERS(state, filters) {
      state.filters = filters;
    },
    SET_CENTER_POSITION(state, position) {
      state.center = position;
      state.locationMarkers = state.locationMarkers.map((location, idx) => {
        if (idx === 0) return location;
        const { lat, lng } = location.position;
        const vet = state.vetList.find((v) => v.id == location.id);
        if (lat == position.lat && lng == position.lng) {
          let activeThumbnail = null;
          if (vet) {
            activeThumbnail = vet.active_thumbnail;
            if(vet.is_seller) {
              activeThumbnail = vet.thumbanil
            }
          }
          return {
            ...location,
            icon: {
              url: activeThumbnail ? activeThumbnail : require('@/assets/svg/active-marker.svg'),
            },
            id: vet.id,
          };
        } else {
          return {
            ...location,
            id: vet.id,
            icon: {
              url: vet.thumbanil,
            },
          };
        }
      });

      state.reUpdate = !state.reUpdate;
    },
    PUSH_LOCATION_MARKER(state, location) {
      state.locationMarkers.push(location);
    },
    CLEAR_MARKER(state) {
      state.locationMarkers = [];
    },
    SET_LOCATION_ADDRESS(state, payload) {
      state.currentLocationAddress = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_CURRENT_LOCATION(state, payload) {
      state.currentLocationCords = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_LOCATION_ADDED(state, payload) {
      state.locationAdded = payload;
      state.reUpdate = !state.reUpdate;
    },
    SET_VET_LOADING(state, payload) {
      state.vetLoading = payload;
    },
    SET_VET_DETAIL(state, payload) {
      state.vet = payload;
    },
    SET_SELLER_DETAIL(state, payload) {
      state.seller = payload;
    },
    SET_SELLER_POSTS(state, payload) {
      state.sellerPosts = payload;
    },
    SET_SELLER_PETS(state, payload) {
      state.sellerPets = payload;
    },
    SET_SELLER_ALL_POSTS(state, payload) {
      state.sellerAllPosts = payload;
    },
    SET_SELLER_ALL_PETS(state, payload) {
      state.sellerAllPets = payload;
    },
  },
  actions: {
    async FETCH_VET_LIST({ commit, state }) {
      try {
        commit('SET_LOADING', true);
        const {
          filters: { radius, zipCode, category },
          currentLocationCords: { lat, lng },
        } = state;

        const resp = await axios.post(
          `/user/show-by-radius/${lng}/${lat}/${radius}/${zipCode}`,
          category === undefined ? {} : {
            'seller_category': [category]
          }
        );

        let vets = resp.data.data.vetWithRadius;
        let seller = resp.data.data.sellers;

        vets.map(vet => {
          vet.is_seller = false;
        })
        
        seller.map(seller => {
          seller.image = resp.data.data.s3Link + seller.avatar;
          seller.is_seller = true;
        })

        vets = [...vets, ...seller]
        commit('SET_VET_LIST', vets);
        if (vets.length) {
          commit('SET_CENTER_POSITION', {
            lat: Number(vets[0].latitude),
            lng: Number(vets[0].longitude),
          });
        }

        vets.forEach((v) => {
          if (!v.thumbanil) {
            v.thumbanil = v.active_thumbnail !== null ? resp.data.data.s3Link + v.active_thumbnail : ""
          }
          commit('PUSH_LOCATION_MARKER', {
            position: {
              lat: Number(v.latitude),
              lng: Number(v.longitude),
            },
            icon: {
              url: v.thumbanil || require('@/assets/images/pinned-dog.png'),
            },
            id: v.id,
            some: 'lalalalal',
            isSeller: v.is_seller
          });
        });
        commit('SET_LOADING', false);
      } catch (error) {
        commit('SET_LOADING', false);
        console.log(error);
      }
    },

    async FETCH_VET_DETAILS({ commit }, id) {
      try {
        commit('SET_VET_LOADING', true);

        const resp = await axios.get(`/user/show-single-vet/${id}`);
        const { vet } = resp.data.data;
        let v = null;
        if (vet && vet.length) {
          v = vet[0];
          if (v.timing && typeof v.timing === 'string') {
            v.timing = JSON.parse(v.timing);
          }
          commit('SET_VET_DETAIL', v);
        }
        commit('SET_VET_LOADING', false);
      } catch (error) {
        commit('SET_VET_LOADING', false);
        console.log(error);
      }
    },
    async FETCH_SELLER_DETAILS({ commit }, id) {
      try {
        commit('SET_VET_LOADING', true);

        const resp = await axios.get(`/web/get-seller-detail/${id}`);
        const { seller, post, animal } = resp.data.data;

        let temp = JSON.parse(seller.timing);
        seller.timing = temp;
        
        commit('SET_SELLER_DETAIL', seller);
        commit('SET_SELLER_POSTS', post);
        commit('SET_SELLER_PETS', animal);
        
        commit('SET_VET_LOADING', false);
      } catch (error) {
        commit('SET_VET_LOADING', false);
        console.log(error);
      }
    },
    async FETCH_PUBLIC_SELLER_DETAILS({ commit }, id) {
      try {
        commit('SET_VET_LOADING', true);

        const resp = await axios.get(`/public/get-seller-detail/${id}`);
        const { seller, post, animal } = resp.data.data;

        let temp = JSON.parse(seller.timing);
        seller.timing = temp;
        
        commit('SET_SELLER_DETAIL', seller);
        commit('SET_SELLER_POSTS', post);
        commit('SET_SELLER_PETS', animal);
        
        commit('SET_VET_LOADING', false);
      } catch (error) {
        commit('SET_VET_LOADING', false);
        console.log(error);
      }
    },
    async FETCH_SELLER_POSTS({ commit }, data) {
      try {
        commit('SET_VET_LOADING', true);

        const resp = await axios.get(`/web/show-user-all-posts/${data.id}/${data.perPage}`);
        console.log('RESPONSE: ', resp);
        // const { seller, post, animal } = resp.data.data;

        // let temp = JSON.parse(seller.timing);
        // seller.timing = temp;
        
        commit('SET_SELLER_ALL_POSTS', resp.data.data);
        
        commit('SET_VET_LOADING', false);
      } catch (error) {
        commit('SET_VET_LOADING', false);
        console.log(error);
      }
    },
  },
};
