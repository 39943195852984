import { DEVICE } from '@/constants';

export default {
  computed: {
    isIOS() {
      if (!window.device) return false;
      return window.device.platform === DEVICE.IOS;
    },
    isAndroid() {
      if (!window.device) return false;
      return window.device.platform === DEVICE.ANDROID;
    },
  },
};
