<template>
  <div class="relative">
    <v-btn
      absolute
      dark
      fab
      color="#F0F0F0"
      @click="$store.commit('popup/CLEAR_POPUP')"
      height="25"
      width="25"
      style="top:11px;right:15px;"
      elevation="0"
    >
      <v-icon color="#333" small>mdi-close</v-icon>
    </v-btn>

    <pinch-zoom>
      <img :src="src" class="img-fluid" />
    </pinch-zoom>
  </div>
</template>

<script>
export default {
  name: "zoom-image",
  props: {
    src: {
      type: String,
      require: true
    }
  }
};
</script>

<style lang="scss" scoped>
.img-fluid {
  max-width: 100%;
  height: auto;
  max-height: 70vh !important;
}
</style>
