<template>
  <div class="relative page-content">
    <v-app-bar
      v-if="!hideAppBar && !blankPage"
      :dark="darkAppBar"
      :color="appBarColor"
      :hide-on-scroll="!topFixed"
      app
      flat
    >
      <slot name="app-bar">
        <v-btn icon v-if="!hideBackIcon" @click="$router.back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>

        <v-toolbar-title v-if="title">{{ title }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          v-if="menuItems.length > 0"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list v-for="(item, index) in menuItems" :key="index">
            <v-list-item @click="item.action" :disbaled="item.disabled">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div v-else>
          <v-btn icon>
            <v-icon color="transparent">mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </slot>
    </v-app-bar>

    <!-- page content -->
    <loader :loading="loading" height="80vh" color="primary">
      <v-container v-if="container">
        <slot></slot>
      </v-container>
      <slot v-else></slot>
    </loader>

    <BottomBar v-if="showBottomBar" />
  </div>
</template>

<script>
import BottomBar from "@/components/bottom-bar/bottom-bar";
import { mapState } from "vuex";

import MixDevice from "@/mixin/mix-device";

export default {
  name: "AppPage",
  components: { BottomBar },
  mixins: [MixDevice],
  props: {
    title: {
      type: String,
      default: "",
    },
    hideBottomBar: {
      type: Boolean,
      default: false,
    },
    hideBackIcon: {
      type: Boolean,
      default: false,
    },
    hideAppBar: {
      type: Boolean,
      default: false,
    },
    blankPage: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Boolean,
      default: false,
    },
    appBarColor: {
      type: String,
      default: "#fff",
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    darkAppBar: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    topFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["HIDE_BOTTOM_BAR"]),
    showBottomBar() {
      return !this.HIDE_BOTTOM_BAR && !this.hideBottomBar && !this.blankPage;
    },
  },
};
</script>

<style lang="scss" scoped></style>
