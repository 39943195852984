export const IMAGE_PLACE_HOLDER = require("@/assets/images/placeholder.png");

export const GENDER_LIST = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const INTEREST_LIST = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Walk",
    value: "walk",
  },
  {
    label: "Breeding",
    value: "breeding",
  },
  {
    label: "Hike",
    value: "hike",
  },
];

export const REGION_LIST = [
  {
    label: "Europe",
    value: "europe",
  },
  {
    label: "Asia",
    value: "asia",
  },
  {
    label: "Middle East",
    value: "middle-east",
  },
  {
    label: "British Columbia",
    value: "british columbia",
  },
];

export const BOTTOM_ROUTES = [
  {
    to: "/",
    icon: "mdi-home",
  },
  {
    to: "/pet-list",
    icon: "mdi-paw",
  },
  {
    to: "/search-vet",
    icon: "mdi-compass",
  },
  {
    to: "/notifications",
    icon: "mdi-bell",
  },
  {
    to: "/profile",
    icon: "mdi-account-circle",
  },
];

export const VIDEO_EXTENSIONS_ARRAY = [
  "webm",
  "mpg",
  "mp2",
  "mpeg",
  "mpe",
  "mpv",
  "ogg",
  "mp4",
  "m4p",
  "m4v",
  "avi",
  "wmv",
  "mov",
  "qt",
  "flv",
  "swf",
  "3gp",
];

export const IMAGE_FILE_EXTENSIONS = ["jpg", "png", "jpeg", "gif"];

export const DEVICE = {
  ANDROID: "Android",
  IOS: "iOS",
};

export const MAX_PROFILE_IMAGE_SIZE = 5; // in mb's

export const GARDEN_SIZES = [
  {
    text: "No Garden",
    value: "no",
  },
  {
    text: "Less than 200 square meter",
    value: "small",
  },
  {
    text: "More than 200 square meter",
    value: "big",
  },
];

export const LANGUAGES = [
  {
    title: "English",
    value: "en",
  },
  {
    title: "Français",
    value: "fr",
  },
  {
    title: "Nederlands",
    value: "nl",
  },
];
