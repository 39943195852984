import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/search-vet",
    name: "search-vet",
    component: () => import("@/views/vet/SearchVet"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pet-list",
    name: "pet-list",
    component: () => import("@/views/pet-list/PetList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pet-detail/:id",
    name: "pet-detail",
    component: () => import("@/views/pet-list/PetDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/public/pet-detail/:id",
    name: "public-pet-detail",
    component: () => import("@/views/pet-list/PublicPetDetail"),
    meta: {
      requiresAuth: false,
      normalRoute : true,
    },
  },
  {
    path: "/pet-detail/:id/interested-in",
    name: "interested-in",
    component: () => import("@/views/pet-list/InterestedIn"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pet-request-submitted",
    name: "pet-request-submitted",
    component: () => import("@/views/pet-list/pet-request-submitted"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/listing",
    name: "listing",
    component: () => import("@/views/pet-list/Listing"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/notifications",
    name: "notification",
    component: () => import("@/views/notification/Notification"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notification/:id",
    name: "notification-detail",
    component: () => import("@/views/notification/NotificationDetail.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile/Profile"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/general-info",
    name: "general-info",
    component: () => import("@/views/profile/GeneralInfo.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/make-donation",
    name: "Donation",
    component: () => import("@/views/profile/Donation.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/edit-profile",
    name: "edit-profile",
    component: () => import("@/views/profile/EditProfile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/adoption-list",
    name: "adoption-list",
    component: () => import("@/views/request-list/AdoptionList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cute-list",
    name: "cute-list",
    component: () => import("@/views/cute-list/CuteList"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("@/views/profile/ChangePassword"),
    meta: {
      requiresAuth: true,
    },
  },
  // feed related routes
  {
    path: "/my-posts",
    name: "my-posts",
    component: () => import("@/views/post/MyPosts"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/create-post",
    name: "create-post",
    component: () => import("@/views/post/create-post"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/post/:id",
    name: "single-post-view",
    component: () => import("@/views/post/PostDetails"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/post/public/:id",
    name: "single-public-post-view",
    component: () => import("@/views/post/PublicPostDetails"),
    meta: {
      requiresAuth: false,
      normalRoute : true,
    },
  },
  {
    path: "/vet-list",
    name: "vet-list",
    component: () => import("@/views/vet/vet-list-view"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/vet-detail/:id",
    name: "vet-detail",
    component: () => import("@/views/vet/vet-detail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/seller-detail/:id",
    name: "seller-detail",
    component: () => import("@/views/seller/SellerDetail"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/public/seller-detail/:id",
    name: "public-seller-detail",
    component: () => import("@/views/seller/PublicSellerDetail"),
    meta: {
      requiresAuth: false,
      normalRoute : true,
    },
  },
  {
    path: "/seller-posts",
    name: "SellerPosts",
    component: () => import("@/views/seller/SellerPosts"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/seller-pets",
    name: "SellerPets",
    component: () => import("@/views/seller/SellerPets"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/seller-pet/:id",
    name: "SellerPet",
    component: () => import("@/views/seller/SellerPet"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/preference-1",
    name: "preference-1",
    component: () => import("@/views/preference/preference-1.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/preference-2",
    name: "preference-2",
    component: () => import("@/views/preference/preference-2.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/privacy/Index.vue"),
    meta: {
      requiresAuth: false,
      normalRoute : true,
    },
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () =>  import("../views/terms/Index.vue"),
    meta: {
      requiresAuth: false,
      normalRoute: true
    }
  },

  // -----------------------------------------------------------------
  //                    TESTING ROUTES
  // -----------------------------------------------------------------

  {
    path: "/drag",
    name: "test",
    component: () => import("@/views/drag"),
    meta: {
      requiresAuth: true,
    },
  },

  // -----------------------------------------------------------------
  //                    LOGIN AUTH RELATED ROUTES
  // -----------------------------------------------------------------
  {
    path: "/pre-login",
    name: "pre-login",
    component: () => import("@/views/PreLogin"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/auth/Login"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/auth/Register"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/register-2",
    name: "register-2",
    component: () => import("@/views/auth/Register-2"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/auth/ForgotPassword"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("@/views/auth/ResetPassword"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/verify-otp",
    name: "verify-otp",
    component: () => import("@/views/auth/VerifyOTP"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/password-success",
    name: "password-success",
    component: () => import("@/views/auth/PasswordSuccess"),
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { requiresAuth } = to.meta;
  const { normalRoute } = to.meta;
  let storeUser = Store.state.user.user;
  let token = localStorage.getItem("token");
  let isAuthenticated = false;

  if (token) {
    if (!storeUser) {
      await Store.dispatch("user/FETCH_USER");
    }

    storeUser = Store.state.user.user;
    if (storeUser) {
      isAuthenticated = true;
    }
  }

  if (requiresAuth && isAuthenticated) {
    if (to.name === "interested-in") {
      const pet = Store.state.pet.activePetDetail;
      if (pet) {
        next();
      } else {
        next("/pet-list");
      }
    } else {
      next();
    }
  } else if (requiresAuth && !isAuthenticated) {
    // todo:: will redirect it to the login page after checking the local-storage that user already seen the pre-screen
    next("/pre-login");
  } else if (!requiresAuth && isAuthenticated && !normalRoute) {
    if (to.name === 'single-public-post-view') {
      next();
    } else {
      next("/");
    }
  } else {
    next();
  }
});

export default router;
