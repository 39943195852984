import router from "@/router";
import axios from "@/plugins/axios";
import { uploadDeviceToken } from "@/helpers";
import UploadService from "@/plugins/upload-service";

import oAuthProvider from "@/firebase";

export default {
  async REGISTER_USER({ commit, getters }) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.post(
        "/auth/register",
        getters.USER_REGISTRATION_DATA
      );
      const { data } = resp.data;
      await uploadDeviceToken();
      commit("CLEAR_CREATE_USER");
      commit("SET_USER", data);
      router.push("/preference-1");
      setTimeout(() => commit("SET_LOADING", false), 1000);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async LOGIN({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.post("/auth/login", payload);
      const { user } = resp.data.data;
      commit("SET_USER", user);
      await uploadDeviceToken();
      router.push("/");
      setTimeout(() => commit("SET_LOADING", false), 1000);
      return true;
    } catch (error) {
      console.log(error);
      commit("SET_LOADING", false);
      return false;
    }
  },
  async FETCH_USER({ commit }) {
    try {
      const resp = await axios.get("/auth/show-user?role=user");
      const { user } = resp.data.data;
      commit("SET_USER", user);
      await uploadDeviceToken();
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async LOGOUT({ commit }) {
    try {
      commit("SET_LOADING", true);
      const provider = await oAuthProvider;
      await axios.post("/auth/logout", {
        device_token: localStorage.getItem("device_token") || "",
      });
      localStorage.removeItem("token");
      await provider.default.logout();
      router.push("/login");
      commit("SET_USER", null);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      localStorage.removeItem("token");
      router.push("/login");
      console.log(error);
      return false;
    }
  },
  async FORGOT_PASSWORD({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      await axios.post("/auth/request-otp", payload);
      router.push(`/verify-otp?email=${payload.email}`);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async VERIFY_OTP({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      await axios.post("/auth/verify-otp", payload);
      router.push(`/reset-password?email=${payload.email}`);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async UPDATE_PASSWORD({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      await axios.post("/auth/update-password", payload);
      router.replace(`/general-info`);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async RESET_PASSWORD({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      await axios.post("/auth/update-new-password", payload);
      router.replace(`/password-success`);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async UPDATE_USER_AVATAR({ commit, state }, payload) {
    try {
      commit("SET_AVATAR_LOADING", true);

      const mediaResp = await UploadService.post("/add-media", payload);
      const media = mediaResp.data.data;
      const resp = await axios.post("/auth/upload-avatar", {
        avatar: media[0],
      });
      commit("SET_USER", { ...state.user, avatar: resp.data.data.user.avatar });
      commit("SET_AVATAR_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_AVATAR_LOADING", false);
      console.log(error);
      return false;
    }
  },
  async GET_POST_COUNT({ commit }) {
    try {
      const resp = await axios.get("/user/get-count");
      const { like, adption, post } = resp.data.data;

      commit("SET_POST_COUNT", {
        adoptionCount: adption,
        cuteCount: like,
        postCount: post,
      });
    } catch (error) {
      console.log(error);
    }
  },
  async UPDATE_USER_PROFILE({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const resp = await axios.post("/auth/update-profile", payload);
      const { user } = resp.data.data;

      if (user.meta_data && typeof user.meta_data === "string") {
        user.meta_data = JSON.parse(user.meta_data);
      }

      commit("SET_USER", user);
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },

  async DELETE_ACCOUNT({ commit }) {
    try {
      commit("SET_LOADING", true);
      await axios.delete("/auth/delete");
      localStorage.clear();
      window.location.reload();
      commit("SET_LOADING", false);
      return true;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
  
  async MAKE_A_DONATION({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await axios.post("/user/donate", payload);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
      console.log(error);
      return false;
    }
  },
};
