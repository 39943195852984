export default {
  mainHeading: "Privacybeleid",
  heading1: "Toestemming",
  heading2: "Informatie die we verzamelen",
  mainHeadingContent:
    "Bij mpawer.app, toegankelijk via https://mpawer.app/, is een van onze belangrijkste prioriteiten de privacy van onze bezoekers. Dit Privacybeleid-document bevat soorten informatie die wordt verzameld en vastgelegd door mpawer.app en hoe we deze gebruiken. Als u nog vragen heeft of meer informatie wenst over ons privacybeleid, aarzel dan niet om contact met ons op te nemen. Dit privacybeleid is alleen van toepassing op onze online activiteiten en is geldig voor bezoekers van onze website met betrekking tot de informatie die ze hebben gedeeld en/of verzameld in mpawer.app. Dit beleid is niet van toepassing op informatie die offline of via andere kanalen dan deze website wordt verzameld. Ons privacybeleid is gemaakt met behulp van de gratis generator voor privacybeleid.",
  heading1Content:
    "Door onze website te gebruiken, stemt u hierbij in met ons privacybeleid en gaat u akkoord met de voorwaarden ervan.",
  heading2Content:
    "De persoonlijke informatie die u wordt gevraagd te verstrekken, en de redenen waarom u wordt gevraagd om deze te verstrekken, zullen u duidelijk worden gemaakt op het moment dat wij u vragen om uw persoonlijke informatie te verstrekken. Als u rechtstreeks contact met ons opneemt, kunnen we aanvullende informatie over u ontvangen, zoals uw naam, e-mailadres, telefoonnummer, de inhoud van het bericht en/of de bijlagen die u ons stuurt, en alle andere informatie die u mogelijk wilt verstrekken. Wanneer u zich registreert voor een Account, kunnen we om uw contactgegevens vragen, inclusief items zoals naam, bedrijfsnaam, adres, e-mailadres en telefoonnummer.",
};
