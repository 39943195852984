<template>
  <div class="advertisement">
    <loader :loading="ad_loading" overlay height="100%">
      <template v-if="!!advertisement">
        <div class="advertisement-image">
          <v-img
            :src="advertisement.media[0]"
            cover
            center
            height="60vh"
          ></v-img>

          <v-progress-circular
            class="timmer"
            :rotate="360"
            :size="25"
            :width="3"
            :value="percentage"
            color="white"
            v-if="!isComplete"
          >
            <span>{{ ad_timer }}</span>
          </v-progress-circular>

          <v-btn
            v-else
            dark
            fab
            class="timmer"
            color="#3333339a"
            height="25"
            width="25"
            @click="CLEAR_SHEET"
          >
            <v-icon color="#fff" small>mdi-close</v-icon>
          </v-btn>
        </div>

        <div class="advertisement-content">
          <div>
            <div class="sponser">
              <span class="golden">Ad</span>
              <span class="text">{{
                $vuetify.lang.t("$vuetify.sponsored_content")
              }}</span>
            </div>

            <p class="advertisement-content-text">
              {{ advertisement.title }}
            </p>

            <p class="text-light">
              {{
                advertisement.description ||
                  $vuetify.lang.t("$vuetify.no_description")
              }}
            </p>
          </div>

          <div>
            <app-button
              @click="ctaAction"
              :disabled="!advertisement.cta_links"
              >{{ advertisement.cta_label }}</app-button
            >
          </div>
        </div>
      </template>
    </loader>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const popupModule = createNamespacedHelpers("popup");
const petModule = createNamespacedHelpers("pet");

export default {
  name: "advertisement-card",
  components: {},
  data() {
    return {
      timer: 30,
      interval: null,
      percentage: 100,
      isComplete: false,
    };
  },
  computed: {
    ...petModule.mapState(["advertisement", "ad_timer", "ad_loading"]),
  },
  methods: {
    ...popupModule.mapMutations(["CLEAR_SHEET"]),
    ...petModule.mapMutations(["SET_AD_TIMER"]),
    // ...petModule.mapActions(["FETCH_PET_ADVERTISEMENT"]),
    async fetchAd() {
      // await this.FETCH_PET_ADVERTISEMENT();
      this.startTimer();
    },
    startTimer() {
      if (!this.advertisement) return this.CLEAR_SHEET();
      this.interval = setInterval(() => {
        this.SET_AD_TIMER(this.ad_timer - 1);
        this.percentage = this.percentage - 100 / this.advertisement.add_time;
        if (this.ad_timer == 0) {
          clearInterval(this.interval);
          window.stopBack = false;
          this.isComplete = true;
        }
      }, 1000);
    },
    ctaAction() {
      // window.cordova.InAppBrowser.open
      window.open(this.advertisement.cta_links, "_system", "location=yes");
      this.CLEAR_SHEET();
    },
    backBtnHandler() {
      this.$event.preventDefault();
      if (!this.isComplete) return;
      this.CLEAR_SHEET();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  mounted() {
    window.stopBack = true;
  },
  created() {
    this.startTimer();

    // this.fetchAd();
  },
};
</script>

<style lang="scss" scoped>
.advertisement {
  height: 100vh;
  background: #fff;

  &-image {
    height: 60vh;
    background: #f4f4f4;
    position: relative;

    .timmer {
      position: absolute;
      top: 1rem;
      right: 1rem;

      span {
        font-size: 10px;
      }
    }
  }

  &-content {
    padding: 1rem 2rem;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .sponser {
      .golden {
        font-size: 8px;
        color: #fff;
        padding: 3px;
        border-radius: 5px;
      }

      .text {
        font-size: 10px;
        line-height: 22px;
        color: #000000;
        opacity: 0.5;
        margin-left: 10px;
      }
    }

    &-text {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-top: 1rem;
    }

    .text-light {
      font-weight: normal;
      font-size: 12px;
      line-height: 17px;
      color: #000000;
      opacity: 0.5;
    }
  }
}
</style>
