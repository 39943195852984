export default {
  GET_USER_KEY(state) {
    return key => state[key];
  },
  GET_USER_PREFERENCE(state) {
    return key => state.preference[key];
  },
  IS_VALID_INPUT(state) {
    const fields = [
      "name",
      "email",
      "contact",
      "address",
      "city",
      "country",
      "gender"
    ];
    return fields.filter(field => !state[field]);
  },
  USER_REGISTRATION_DATA(state) {
    const {
      name,
      email,
      password,
      password_confirmation,
      formatted_contact,
      address,
      postal_code,
      city,
      country,
      longitude,
      latitude,
      gender,
      role,
      country_code
    } = state;
    return {
      name,
      email,
      password,
      password_confirmation,
      contact: formatted_contact,
      address,
      postal_code,
      city,
      country,
      longitude,
      latitude,
      gender,
      role,
      country_code
    };
  }
};
