import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@/plugins/index.js";
import "@/global-methods";
import "@mdi/font/css/materialdesignicons.min.css";
import "@/scss/main.scss";
import "@/assets/css/main.css";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import { SENTRY_DSN } from "@/config";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    Vue,
    dsn: SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "mpawer.dev", "", "", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// Vue.config.errorHandler = (err, vm, info) => {
//   // window.showAlert(`Some Unhandled Error {${info}}`, "ERROR");
//   throw new Error(info);
// };
