export default {
  mainHeading: "Voorwaarden",
  heading1: "Koekjes",
  heading2: "Vergunning",
  mainHeadingContent:
    "Welkom bij mpawer.app! Deze algemene voorwaarden beschrijven de regels en voorschriften voor het gebruik van de website van Mpawer, te vinden op https://mpawer.app/. Door deze website te bezoeken, gaan we ervan uit dat u deze algemene voorwaarden accepteert. Blijf mpawer.app niet gebruiken als u niet akkoord gaat met alle volgende terminologie die van toepassing is op deze Algemene voorwaarden, Privacyverklaring en Disclaimer en alle Overeenkomsten: 'Klant', 'U' en 'Uw' verwijst naar u , de persoon die zich op deze website aanmeldt en voldoet aan de algemene voorwaarden van het bedrijf. 'Het bedrijf', 'onszelf', 'wij', 'onze' en 'ons' verwijst naar ons bedrijf. 'Partij', 'Partijen' of 'Ons' verwijst zowel naar de Klant als naar onszelf. Alle voorwaarden verwijzen naar het aanbod, de aanvaarding en de overweging van de betaling die nodig is om het proces van onze assistentie aan de klant op de meest geschikte manier uit te voeren met het uitdrukkelijke doel om te voldoen aan de behoeften van de klant met betrekking tot het leveren van de vermelde diensten van het bedrijf, in overeenstemming met en onderworpen aan de geldende wetgeving van Nederland. Elk gebruik van de bovenstaande terminologie of andere woorden in het enkelvoud, meervoud, hoofdletters en/of hij/zij of zij, wordt als onderling verwisselbaar beschouwd en wordt daarom als een verwijzing ernaar beschouwd.",
  heading1Content:
    "Wij maken gebruik van cookies. Door naar mpawer.app te gaan, stemde u ermee in om cookies te gebruiken in overeenstemming met het privacybeleid van Mpawer. De meeste interactieve websites gebruiken cookies om ons de gegevens van de gebruiker te laten ophalen voor elk bezoek. Cookies worden door onze website gebruikt om de functionaliteit van bepaalde gebieden mogelijk te maken en het bezoekers van onze website gemakkelijker te maken. Sommige van onze gelieerde/advertentiepartners kunnen ook cookies gebruiken.",
  heading2Content:
    "Tenzij anders vermeld, bezitten Mpawer en/of zijn licentiegevers de intellectuele eigendomsrechten voor al het materiaal op mpawer.app. Alle intellectuele eigendomsrechten zijn voorbehouden. U kunt hier toegang toe krijgen vanaf mpawer.app voor uw eigen persoonlijk gebruik, onderhevig aan beperkingen die in deze algemene voorwaarden zijn vastgelegd.",
};
