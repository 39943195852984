export default {
  TOGGLE_GRID_VIEW(state) {
    state.gridView = !state.gridView;
    localStorage.setItem("grid-view", state.gridView);
  },
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  // --------- PETS----------
  UPDATE_PET_LIST(state, payload) {
    const copyPetList = [...state.petList];
    const index = copyPetList.findIndex(pet => pet.id === payload.id);
    if (index !== -1) {
      copyPetList[index] = payload;
      state.petList = [...copyPetList];
    }
  },
  SET_FEATURED_PETS(state, payload) {
    state.featurePets = payload;
  },
  SET_PETS_LIST(state, payload) {
    state.petList = [...state.petList, ...payload];
  },
  CLEAR_PETS_LIST(state) {
    state.petList = [];
    state.page = 1;
    state.hasNext = true;
    // state.selectedRace = [];
  },
  UPDATE_PET_FILTER(state, payload) {
    const filters = { ...state.filter };
    for (const key in payload) {
      filters[key] = payload[key];
    }
    state.filter = { ...filters };
  },
  CLEAR_ALL_FILTERS(state) {
    state.filter = {
      categoryId: "",
      keyword: "",
      gender: "",
      region: "",
      race_id: []
    };
    state.selectedRace = [];
  },
  // -------------------------
  SET_PET_RACE(state, payload) {
    state.petRace = payload;
  },
  SET_RACE_LOADER(state, payload) {
    state.raceLoading = payload;
  },
  SET_SELECTED_RACE(state, payload) {
    state.selectedRace = payload;
  },
  CLEAR_SELECTED_RACE(state) {
    state.selectedRace = [];
  },
  SET_PET_CATEGORY(state, payload) {
    state.petCategoryList = payload;
  },
  SET_CAT_LOADING(state, payload) {
    state.petCatLoading = payload;
  },
  SET_ACTIVE_PET_DETAIL(state, payload) {
    state.activePetDetail = payload;
  },
  SET_PET_PAGINATION(state, { page, hasNext }) {
    state.page = page;
    state.hasNext = hasNext;
  },
  SET_FEATURED_LOADING(state, payload) {
    state.featuredLoading = payload;
  },
  SET_TINDER_LISTING(state, payload) {
    state.tinderListing = [...state.tinderListing, ...payload];
  },
  CLEAR_TINDER_LISTING(state) {
    state.tinderListing = [];
    state.tinderNextPage = true;
    state.tinderPage = 1;
  },
  SET_TINDER_LOADING(state, payload) {
    state.tinderLoading = payload;
  },
  SET_TINDER_PAGINATION(state, { page, hasNext }) {
    state.tinderPage = page;
    state.tinderNextPage = hasNext;
  },
  SET_PET_LIST(state, { list, type }) {
    state[type] = list;
  },
  // advertisement
  SET_ADVERTISEMENT(state, advertisement) {
    state.advertisement = advertisement;
  },
  SET_AD_TIMER(state, time) {
    state.ad_timer = +time;
  },
  PET_AD_LOADING(state, loading) {
    state.ad_loading = loading;
  }
};
