<template>
  <v-bottom-navigation
    app
    class="rounded-t-lg overflow-hidden"
    color="primary"
    background-color="rgba(255,255,255,0.95)"
    :height="isIOS ? '76px' : '56px'"
    :style="`padding-bottom:${isIOS && '1rem'};z-index:111`"
  >
    <div class="btn-container">
      <v-btn v-for="menu in bottomMenu" :to="menu.to" :key="menu.title">
        <v-icon size="24px" v-if="menu.icon !== 'mdi-home'">{{
          menu.icon
        }}</v-icon>
        <HomeIcon v-else />
      </v-btn>
    </div>
  </v-bottom-navigation>
</template>

<script>
import { BOTTOM_ROUTES } from "@/constants";
import HomeIcon from "@/components/svg/icon/home";
import MixDevice from "@/mixin/mix-device";

export default {
  name: "bottom-bar",
  components: { HomeIcon },
  mixins: [MixDevice],
  computed: {
    bottomMenu() {
      return BOTTOM_ROUTES;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &::v-deep .v-btn {
    height: 100%;
    width: 100%;
    min-width: unset;

    &:not(.v-btn--active) {
      color: #c0c0c0 !important;
    }
  }
}

.rounded-t-lg {
  border-radius: 20px 20px 0px 0px !important;
}
</style>
