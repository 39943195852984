import moment from 'moment';
import axios from '@/plugins/axios';

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function showAlert(alertText, title = '') {
  if ('cordova' in window) {
    navigator.notification.alert(alertText, () => {}, title);
  } else {
    window.alert(alertText);
  }
}

export const bytesToMb = (val) => Number(val) / 1024 / 1024;

export const mbToBytes = (val) => Number(val) * 1024 * 1024;

export function calculateAge(dob) {
  const today = moment();
  dob = moment(dob);

  const years = today.diff(dob, 'years');
  const months = today.diff(dob, 'months');
  const days = today.diff(dob, 'days');

  let yearStr = 'Yr';
  let monthStr = 'Mo';
  let dayStr = 'D';

  if (years > 1) yearStr = "Yr's";
  if (months > 1) monthStr = "Mo's";
  if (days > 1) dayStr = "D's";

  if (years >= 1) return `${years} ${yearStr}`;
  if (months >= 1) return `${months} ${monthStr}`;
  if (days >= 1) return `${days} ${dayStr}`;

  return 'Nothing';
}

export function uploadDeviceToken(force = true) {
  return new Promise((resolve) => {
    const device_token = localStorage.getItem('device_token');
    const canUpload = localStorage.getItem('upload-token') == 'true';
    if (!window.cordova) return resolve(true);

    if (!force) {
      if (!device_token) return resolve(true);
      if (!canUpload) return resolve(true);
    }

    if (!device_token) return resolve(false);

    axios
      .post('/web/add-user-device-token', { device_token })
      .then((r) => {
        localStorage.setItem('device_token_id', r.data.data.id);
        resolve(true);
      })
      .catch((e) => {
        window.showAlert(e.toString(), 'ERROR while uploading token');
      });
  });
}

export function backgroundModeEnable() {
  if (!window.cordova) return false;
  window.cordova.plugins.backgroundMode.enable();
}

export function backgroundModeDisable() {
  if (!window.cordova) return false;
  window.cordova.plugins.backgroundMode.disable();
}
