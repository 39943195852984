import Vue from "vue";
import VueAxios from "vue-axios";
import { GOOGLE_MAP_KEY } from "@/config";
import "./filters";

import axios from "./axios";
import AppPage from "@/layout/AppPage";
import AppButton from "@/components/button/AppButton";
import Loader from "@/components/loader/loader";
import VueClipboard from "vue-clipboard2";
import * as VueGoogleMaps from "vue2-google-maps";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import PinchZoom from "vue-pinch-zoom";
import Notifications from "vue-notification";
import velocity from "velocity-animate";

Vue.use(VueAxios, axios);
Vue.use(VuePlyr);
Vue.use(VueClipboard);
Vue.use(Notifications, { velocity });
Vue.component("AppPage", AppPage);
Vue.component("AppButton", AppButton);
Vue.component("Loader", Loader);
Vue.component("pinch-zoom", PinchZoom);

Vue.use(VueGoogleMaps, {
  load: {
    key: GOOGLE_MAP_KEY,
    libraries: "places",
  },
});
