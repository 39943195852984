<template>
  <v-card class="rounded-t-xl">
    <v-btn
      absolute
      dark
      fab
      color="#F0F0F0"
      @click="$store.commit('popup/CLEAR_POPUP')"
      height="25"
      width="25"
      style="top: 11px; right: 15px"
      elevation="0"
    >
      <v-icon color="#333" small>mdi-close</v-icon>
    </v-btn>

    <p class="title">
      {{ $vuetify.lang.t("$vuetify.add_race") }}
    </p>
    <v-card-text>
      <div v-if="!raceLoading">
        <v-virtual-scroll
          :bench="0"
          :items="filteredItems"
          height="300"
          item-height="64"
          v-if="filteredItems.length"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.id" @click="item.toggleSelect(item)">
              <v-list-item-content>
                <v-list-item-title>
                  <span :class="item.selected ? 'primary--text' : ''">
                    {{ lang == "en" ? item.name : lang == "nl" ? item.nt_name : item.fr_name }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action v-if="item.selected">
                <v-icon small color="primary"> mdi-check </v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
        <div class="text-center py-11" v-else>
          <h2 class="red--text">No Categories Found</h2>
        </div>
      </div>

      <div v-else>
        <v-skeleton-loader
          v-for="n in 3"
          :key="n"
          type="list-item-two-line, divider"
        ></v-skeleton-loader>
      </div>

      <div class="d-flex align-items-center justify-content-between mt-5">
        <app-button
          class="mr-4"
          color="grey-light"
          :block="false"
          @click="closeRacePopup"
          >{{ $vuetify.lang.t("$vuetify.close") }}</app-button
        >
        <app-button
          style="flex: 1"
          :disabled="raceLoading || !filteredItems.length"
          :block="false"
          @click="addRace"
          >{{ $vuetify.lang.t("$vuetify.add_race") }}</app-button
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapMutations } = createNamespacedHelpers("pet");
export default {
  name: "pet-race",
  data() {
    return {
      localSelected: [],
      lang: localStorage.getItem("lang"),
    };
  },
  computed: {
    ...mapState(["raceLoading", "selectedRace", "petRace"]),
    items() {
      return this.petRace.map((p) => {
        let selected = false;

        const index = this.localSelected.findIndex(
          (local) => local.id === p.id
        );

        if (index !== -1) selected = true;

        return {
          ...p,
          selected,
          toggleSelect: this.toggleRace,
        };
      });
    },

    filteredItems() {
      return this.items.filter((item) => {
        if (this.lang == "en" && item.name) return item;
        if (this.lang == "nl" && item.nt_name) return item;
        if (this.lang == "fr" && item.fr_name) return item;
      });
    },
  },
  methods: {
    ...mapMutations(["SET_SELECTED_RACE"]),
    toggleRace(race) {
      const copyPetRaceArr = [...this.localSelected];
      const index = copyPetRaceArr.findIndex((p) => p.id === race.id);

      if (index === -1) {
        copyPetRaceArr.push(race);
      } else {
        copyPetRaceArr.splice(index, 1);
      }
      this.localSelected = [...copyPetRaceArr];
    },
    closeRacePopup() {
      this.$store.commit("popup/CLEAR_POPUP");
    },
    addRace() {
      this.SET_SELECTED_RACE(this.localSelected);
      this.closeRacePopup();
    },
  },
  created() {
    this.localSelected = this.selectedRace;
  },
};
</script>

<style lang="scss" scoped>
p.title {
  font-weight: 500;
  font-size: 16px !important;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
  padding: 1.3rem 1.2rem 1rem 2.3rem;
}
</style>
