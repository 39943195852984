import mutations from "./mutations";
import actions from "./actions";

export default {
  namespaced: true,
  state: {
    loading: false,
    feeds: [],
    limitPerPage: 10,
    adPosition: 3,
    page: 1,
    hasNext: true,
    //
    currentPost: null,
    // comments
    comment_loading: false,
    comments: [],
    //
    myPost: [],
    myPostPage: 1,
    myPostNextPage: true,
    postUploadProgress: 0,
  },
  getters: {},
  mutations,
  actions,
};
