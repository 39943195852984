<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 10.3609C3 9.1048 3.59 7.92169 4.59325 7.16593L10.6494 2.60381C11.7185 1.79848 13.1917 1.79876 14.2604 2.60449L20.3109 7.1659C21.3134 7.92171 21.9029 9.10439 21.9029 10.3599V19.275C21.9029 20.9318 20.5597 22.275 18.9029 22.275H6C4.34315 22.275 3 20.9318 3 19.275V10.3609ZM10.9239 14.7848C10.5932 14.5354 10.1229 14.6013 9.87345 14.9319C9.62401 15.2626 9.68988 15.7329 10.0206 15.9823C10.7185 16.5088 11.6008 16.7521 12.4514 16.7521C13.3019 16.7521 14.1842 16.5088 14.8821 15.9823C15.2128 15.7329 15.2787 15.2626 15.0293 14.9319C14.7798 14.6013 14.3095 14.5354 13.9788 14.7848C13.5835 15.083 13.0333 15.2521 12.4514 15.2521C11.8694 15.2521 11.3192 15.083 10.9239 14.7848Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "home-icon",
  props: { color: { type: String, default: "#C0C0C0" } },
};
</script>

<style lang="scss" scoped></style>
