import axios from "axios";
import get from "lodash/get";
import { UPLOAD_API_END_POINT } from "@/config";

export const fetchToken = async () => {
  try {
    const token = localStorage.getItem("upload-token");
    if (!token) {
      const resp = await axios.get(`${UPLOAD_API_END_POINT}/token`);
      localStorage.setItem("upload-token", resp.data);
    }
  } catch (error) {
    console.log(error);
    window.showAlert("Not able to fetch upload token");
  }
};

const uploadService = axios.create({
  baseURL: UPLOAD_API_END_POINT,
  headers: {
    "content-type": "multipart/form-data"
  }
});

uploadService.interceptors.request.use(
  config => {
    // const token = localStorage.getItem("upload-token");
    // if (token) {
    //   config.headers["X-CSRF-TOKEN"] = token;
    // }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

uploadService.interceptors.response.use(
  resp => resp,
  error => {
    let errorMsg = get(
      error,
      "response.data.message",
      "Something Went Wrong...!"
    );

    const errorsArr = [
      "Token not provided",
      "Token has expired",
      "Your token has expired. Please, login again.",
      "Token Signature could not be verified.",
      "User not found"
    ];

    if (errorsArr.includes(errorMsg)) {
      localStorage.removeItem("token");
    }

    if (errorMsg === "Validation Error") {
      const errorObj = { ...error.response.data };
      delete errorObj.message;

      for (const key in errorObj) {
        const element = errorObj[key];
        const elKeys = Object.keys(element);

        if (elKeys.length) {
          errorMsg = element[elKeys[0]][0];
        }
      }
    }

    window.showAlert(errorMsg, "Some Error");
    return Promise.reject(error);
  }
);

export default uploadService;
