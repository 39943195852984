import actions from "./actions";
import mutations from "./mutations";

let gridView = localStorage.getItem("grid-view");

if (gridView) {
  gridView = gridView === "true";
} else {
  gridView = true;
}

export default {
  namespaced: true,
  state: {
    gridView: gridView,
    loading: false,
    featuredLoading: false,
    featurePets: [],
    // pagination
    petList: [],
    page: 1,
    hasNext: true,
    filter: {
      categoryId: "",
      keyword: "",
      gender: "",
      region: "",
      race_id: [],
    },
    // category
    petCategoryList: [],
    petCatLoading: false,
    // pet race
    petRace: [],
    raceLoading: false,
    selectedRace: [],
    // current pet
    activePetDetail: null,
    // tinder-view
    tinderListing: [],
    tinderLoading: false,
    tinderNextPage: true,
    tinderPage: 1,
    // cuteList
    cuteList: [],
    adoptionList: [],
    coOwnedList: [],
    // advertisement
    advertisement: null,
    ad_timer: 0,
    ad_loading: false,
  },
  getters: {
    IS_GRID_VIEW(state) {
      return state.gridView;
    },
  },
  mutations: mutations,
  actions: actions,
};
