import axios from '@/plugins/axios';

const defaultModalOptions = () => ({
  overlayColor: "#000000",
  overlayOpacity: ".4",
  hideOverlay: false,

  fullscreen: false,
  maxWidth: "100%",
  persistent: false,
  transition: "dialog-bottom-transition",
});

const initialState = () => ({
  show: false,
  component: null,
  componentProps: {},
  modalOptions: { ...defaultModalOptions() },
});

const buttonSheetOptions = () => ({
  height: "85vh",
  fullscreen: false,
  showAppBar: false,
  appBarTitle: "",
});

export default {
  namespaced: true,
  state: {
    ...initialState(),
    showSheet: false,
    sheetComponent: "filter",
    sheetOptions: buttonSheetOptions(),
  },

  getters: {},
  mutations: {
    SET_POPUP(
      state,
      {
        show,
        component,
        componentProps = {},
        modalOptions = defaultModalOptions(),
      }
    ) {
      state.show = show;
      state.component = component;
      state.componentProps = componentProps;
      state.modalOptions = modalOptions;
    },

    CLEAR_POPUP(state) {
      let initial = initialState();
      for (const key in initial) {
        if (key === "component" || key === "componentProps") {
          state[key] = initial[key];
          // document.querySelector("html").style.overflowY = 'hidden'
        } else {
          state[key] = initial[key];
        }
      }
    },
    SET_SHEET(
      state,
      { showSheet, sheetComponent, sheetOptions, componentProps }
    ) {
      state.sheetOptions = {
        ...state.sheetOptions,
        ...sheetOptions,
      };
      state.sheetComponent = sheetComponent;
      state.showSheet = showSheet;
      if (componentProps) {
        state.componentProps = componentProps;
      }
    },
    CLEAR_SHEET(state) {
      state.showSheet = false;
      state.sheetOptions = buttonSheetOptions();
      state.sheetComponent = "";
      state.componentProps = {};
      document.querySelector('html').removeAttribute("style")
    },
  },
  actions: {
    async getSellerCategories (context, per_page = 100) {
      try {
        return await axios.post(`/web/get-seller-categories/${per_page}`)
      } catch (error) {
        console.error(error);
      }
    }
  },
};
