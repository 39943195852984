import actions from "./actions";
import getters from "./getters";

const createUserInitialState = () => {
  return {
    name: "",
    email: ``,
    password: "",
    password_confirmation: "",
    contact: "",
    formatted_contact: "",
    address: "",
    postal_code: "",
    city: "",
    country: "",
    longitude: "",
    latitude: "",
    gender: "",
    country_code: "BE",
    role: "user",
    preference: {
      noOfKids: 0,
      kidsAges: [],
      garden_size: "no",
      hasGarden: false,
      gardenLength: "",
      gardenWidth: "",
      otherAnimals: 0,
      otherAnimalsTypes: [],
    },
  };
};

const defaultState = () => {
  return {
    isAuthenticated: false,
    loading: false,
    user: null,
    avatarLoading: false,
    // create user
    ...createUserInitialState(),
    postCount: {
      adoptionCount: 0,
      cuteCount: 0,
      postCount: 0,
    },
  };
};

export default {
  namespaced: true,
  state: {
    ...defaultState(),
  },
  getters,
  mutations: {
    SET_AUTHENTICATION(state, payload) {
      state.isAuthenticated = payload;
    },
    SET_USER_FIELD(state, { key, value }) {
      state[key] = value;
    },
    SET_USER_PREFERENCE(state, { key, value }) {
      state.preference[key] = value;
    },
    SET_ALL_USER_PREFERENCE(state, payload) {
      state.preference = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_POST_COUNT(state, payload) {
      state.postCount = payload;
    },
    SET_AVATAR_LOADING(state, payload) {
      state.avatarLoading = payload;
    },
    CLEAR_ALL_USER_STATE(state) {
      const initialState = { ...defaultState() };
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
    CLEAR_CREATE_USER(state) {
      const initialState = { ...createUserInitialState() };
      for (const key in initialState) {
        state[key] = initialState[key];
      }
    },
  },
  actions,
};
