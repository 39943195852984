export default {
  // Onboarding
  adoption_in_few_clicks: 'Adoption in few clicks',
  get_started: 'Get started',
  share_animal_care: 'Share animal care',

  // Login Screen
  login: 'Login',
  enter_your_email_and_password: 'Enter your email and password',
  email: 'Email',
  password: 'Password',
  forgot_your_password: 'Forgot Your Password?',
  create_new_account: 'Create New Account',
  or_signup_with: 'Or Signup With',
  and: 'and',
  others: 'others',
  dogs: 'Dogs',
  cats: 'Cats',
  other_animal: 'Other Animal',

  // Create New Account
  create_account: 'Create Account',
  enter_your_details_below_and_get_started:
    'Enter your details below and get started',
  name: 'Name',
  phone_number: 'Phone Number',
  enter_your_address: 'Enter Your Address',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  next: 'Next',
  back: 'Back',
  step_1_of_2: 'Step 1 of 2',
  step_2_of_2: 'Step 2 of 2',
  already_have_a_account: 'Already have a account?',
  confirm_password: 'Confirm Password',

  // Preferences
  preferences: 'Preferences',
  family: 'Family',
  kids_in_house: 'Kids in House',
  skip: 'Skip',
  do_you_have_a_garden_in_your_house: 'Do you have a garden in your house?',
  garden_size: 'Garden Size',
  no_garden: 'No Garden',
  less_than_200_square_meter: 'Less than 200 square meter',
  more_than_200_square_meter: 'More than 200 square meter',
  how_many_animals_you_have_in_house: 'How many animals you have in house?',
  enter_age_of_kid: 'Enter age of Kid',

  // Fogot Password
  lets_get_you_new_password: 'Lets get you new password',
  submit: 'Submit',
  verify_your_otp: 'Verify Your OTP (One Time Password)',
  enter_the_6_digit_code_you_received_in_your_email:
    'Enter the 6 digit code you received in your email',
  verify: 'Verify',

  //Social Wall
  enter_caption: 'Enter Caption',
  publish: 'Publish',
  upload_with: 'Upload With',
  add_your_comment: 'Add your comment',
  report_post: 'Report Post',
  block_user: 'Block User',
  terms_check_label:
    'By creating your account on this platform you are accepting our',
  terms_check_label1: "I'm accepting your's",
  enter_a_reason: 'Enter a reason',
  report_user_detail:
    "Help us understand what's happening with this user. How would you describe it?",
  report_post_detail:
    "Help us understand what's happening with this post. How would you describe it?",
  thanks_for_reporting: 'Thank you for submitting a report.',
  review_for_reporting:
    'We will review your report and notify you of our decision with in 24 hours.',

  pawed_by: 'Pawed By',

  //Pet list screen
  add_filters: 'Add Filters',
  species: 'Species',
  race: 'Race',
  add_race: 'Add Race',
  close: 'Close',
  apply_filters: 'Apply Filters',
  clear_all_filters: 'Clear All Filters',
  hi_human: 'Hi Human!',
  are_you_ready_to_be_my_new_family: 'Are you ready to be my new family?',
  lets_explore: 'Lets Explore',
  featured_pet: 'Featured Pet',
  all_listings: 'All Listings',
  no_pet_found: 'No Pet Found',
  no_featured_pet: 'No Featured Pet',

  //Pet detail
  looking_for_a_new_home: 'Looking for a new home',
  go_for_a_walk_with_someone: 'Go for a walk with someone',
  info: 'Info',
  owner: 'Owner',
  active_hour: 'Active Hour',
  kids_firendy: 'Kids Friendly',
  not_kids_firendy: 'Not Kids Friendly',
  dogs_firendy: 'Dogs Friendly',
  not_dogs_firendy: 'Not Dogs Friendly',
  other_animals_friendly: 'Other animals friendly',
  not_other_animals_friendly: 'Not other animals friendly',
  i_am_interested: 'I am interested',
  what_are_you_interested_in: 'What are you interested in?',
  disclaimer: 'Disclaimer',
  your_contact_details: 'Your Contact Details',
  edit: 'Edit',
  submit_request: 'Submit Request',
  no_description: 'No Description',

  //Vet screen
  find_vets_near_you: 'Find vets near you',
  list: 'List',
  filters: 'Filters',
  use_my_current_location: 'Use my current location',
  vet_detail: 'Vet Detail',
  opening_hours: 'Opening Hours',
  seller_detail: 'Seller Detail',
  seller_all_posts: 'Seller All Posts',
  seller_all_pets: 'Seller All Pets',

  //Notification screen
  notifications: 'Notifications',
  no_notification_for_now: 'No Notification for now…!',
  mark_all_read: 'Mark All Read',
  photo: 'Photo',

  //Profile screen
  adoption_requests: 'Adoption Requests',
  no_list: 'No List',
  adoption_list: 'Adoption List',
  co_ownership: 'Co-Ownership',
  feed_post: 'Feed post',
  no_post_at_the_moment: 'No Post at the moment',
  cute_list: 'Cute List',
  no_cute_list: 'No Cute List',
  general_info: 'General Info',
  make_donation: 'Make Donation',
  make_donation_placeholder: 'Enter amount to donate',
  country_code: 'Country Code',
  edit_profile: 'Edit Profile',
  create_new_password: 'Create New Password',
  old_password: 'Old Password',
  update_password: 'Update Password',
  privacy_policy: 'Privacy Policy',
  terms_and_conditions: 'Terms and Conditions',
  privacy_policy_title: 'Privacy Policy mPawer',
  terms_and_conditions_title: 'Terms and Conditions',
  logout: 'Logout',
  upload_profile_image: 'Upload Profile Image',
  animal_in_house: 'Animal in House',
  kids: 'Kids',
  garden: 'Garden',
  animal: 'Animal',
  age: 'Age',
  select_animal_type: 'Select Animal Type',

  phone: 'Phone',
  kid: 'Kid',
  change: 'Change',
  change_password: 'Change Password',
  current_location: 'Current Location',
  address: 'Address',
  description: 'Description',
  closed: 'Closed',
  cancel: 'Cancel',
  done: 'Done',
  report_listing: 'Report Listing',
  adoption: 'Adoption',
  go_for_a_walk: 'Go For A Walk',
  in_house: 'In House',
  travel_with_pet: 'Travel With Pet',
  how_would_you_like_to_own: 'How would you like to own?',
  cool: 'Cool',
  is_excited_about: 'is excited about new home',
  paws_crossed: 'Paws crossed! Thanks for caring for',
  become_friends: 'We might just become best friends. ❤️',
  please_choose_in_which: 'Please choose in which days you will have',
  enter_phone: 'Enter Phone',
  enter_email: 'Enter Email',
  update: 'Update',
  one_tap: 'One tap for full day',
  two_tap: 'Double Tap for Am',
  triple_tap: 'Triple Tap for Pm',
  pet_request_submitted: 'Request Submitted Successfully',
  pet_request_description:
    'Your adoption request has been submitted to the owner, for further details you can check the status of application from your profile',
  my_profile: 'My Profile',
  not: 'Not',
  sponsored_content: 'Sponsored Content',
  search_pet: 'Search Pet',
  enter_your_email: 'Enter Your Email',
  save: 'Save',
  setup_new_password: 'Setup New Password',
  password_updated_successfully: 'Your password has been updated successfully',
  delete_post: 'Delete Post',
  language: 'Language',
  vets_located_near_you: 'Near you',
  get_direction: 'Route',
  search_by_zip_code: 'Search by Zip Code',
  enter_zip_code: 'Enter Zip Code',
  search_radius: 'Search Radius',
  cancel_request: 'Cancel Request',
  no_feature_pet: 'No Featured pet',
  house: 'House',
  search_by_category: 'Search By Category',
  promoted_content: 'Promoted Content',
  make_a_donation: 'Make a donation',
  with_this_donation: 'With this donation',
  recieve_the_care: 'will receive the care that is needed!',
  amount: 'Enter the amount',
  your_donation: 'Make Your Donation',
  support_mpawer: 'Support mPawer and help us make a difference!',
  route: 'Route',
  make_donation_placeholder_input: 'Enter the amount',
};
